.four-zero-four {
  width: 100%;
  text-align: center;
  min-height: calc(100vh - 540px);
  margin-top: 70px;
  h1 {
    font-size: $DesktopCopyRegular50;
  }
  h3 {
    font-size: $DesktopCopyRegular35;
  }
  h1,
  h3 {
    color: $primaryBlue;
  }
  .four-zero-four-image {
    width: 184px;
    height: auto;
    margin: 50px auto;
    display: block;
  }
  @media screen and (min-width: $screen-md-min) {
    min-height: calc(100vh - 575px);
    margin-top: 80px;
  }
  @media screen and (min-width: $screen-lg-min) {
    margin-top: 100px;
  }
  button {
    &:focus {
      border: 0;
      outline: $focus-outline;
    }
  }
}
