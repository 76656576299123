.rich-text-main {
  .rich-text-component {
    .rich-text-wrap {
      padding: 30px 16px 0;
      * {
        font-family: $fontfamilyDefault;
        text-align: inherit;
      }
      a {
        color: $primaryBlue;
        word-break: break-word;
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
      @media (min-width: $screen-md-min) {
        padding: 40px 45px 0;
      }
      @media (min-width: $screen-lg-min) {
        padding: 40px 65px 0;
      }
    }
  }
}


