/* sticky nav bar */

.sticky-nav-wrap {
  height: 100px;
  background: $primaryWhite;

  @media (max-width: $screen-sm-max) {
    height: 60px;
  }

  &.sticky-nav-fixed {
    position: fixed;
    top: 0;
    box-shadow: $sticky-nav-bar;
    z-index: $zindex-overlay;
  }

  &.auto-suggest {
    box-shadow: none;
  }

  .sticky-nav-bar {
    position: relative;

    @media (max-width: $screen-sm-max) {
      height: 60px;
      padding: 10px;
    }

    @media (min-width: $screen-md-min) {
      height: 100px;
      padding: 25px 30px 22px;
    }

    @media (min-width: $screen-lg-min) {
      height: 100px;
      padding: 20px 23px 16px 40px;
    }

    .sticky-nav-bar-common {
      padding: 0;
      justify-content: space-between;

      /* Mega menu hamburger icon */

      .megamenu-hamburger-icon {
        padding: 0;
        border: 0;
        outline: none;
        float: left;

        &:focus {
          outline: $focus-outline;
          outline-offset: 1px;
        }

        @media (min-width: $screen-md-min) {
          margin: 11px 0;
        }

        @media (min-width: $screen-lg-min) {
          margin: 15px 0;
        }

        img {
          width: 35px;
          height: 29px;
          border: none;
        }

        &:focus {
          box-shadow: none;
        }

        @media (max-width: $screen-sm-max) {
          display: none;
        }
      }

      /* Search Input */

      .search-input {
        display: initial;
        margin-left: 33px;
        width: 350px;
        float: left;
        background-color: $primaryWhite;

        &.search-input-expand {
          @media (max-width: $screen-sm-max) {
            width: 100%;
            float: left;
          }

          width: calc(100% - 70px);
        }

        @media (max-width: $screen-sm-max) {
          width: 90%;
          float: none;
          margin: 0 auto;
        }

        @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
          width: calc(100% - 100px);
        }

        @media screen and (min-width:$screen-tab) and (max-width: $screen-xl-min - 1) {
          width: 280px;
        }

        .search-input-text {
          width: 350px;
          position: relative;
          display: inline-block;

          @media (max-width: $screen-sm-max) {
            width: 100%;
            float: none;
            margin: 0 auto;
            display: block;
          }

          @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
            width: calc(100% - 0px);
          }

          @media screen and (min-width:$screen-tab) and (max-width: $screen-xl-min - 1) {
            width: 280px;
          }

          &.search-input-expand {
            @media (max-width: $screen-sm-max) {
              width: 85%;
              float: left;
            }

            width: calc(100% - 70px);
          }

          .bmo-search-icon {
            height: 30px;
            width: 29px;
            top: 15px;
            left: 21px;
            opacity: 1;
            position: absolute;
            z-index: 1;
            padding-right: 5px;
            background: url('assets/images/quick_search.png');
            background-repeat: no-repeat;
            background-size: contain;
            border: 0;

            @media (max-width: $screen-sm-max) {
              width: 20px;
              height: 20px;
              top: 10px;
            }

            @media (min-width: $screen-md-min) {
              width: 24px;
              height: 26px;
            }

            @media (min-width: $screen-tab) {
              top: 18px;
            }

          }

          input {
            font-family: $fontfamilyDefault;
            font-size: $MegaMenuLink20;
            padding: 14px;
            padding-left: 63px;
            border: none;
            outline: 0;
            width: 100%;
            color: $secondarInputGray;
            border-radius: 30px;
            background: rgba(227, 225, 225, 0.61);

            &:focus {
              outline: $focus-outline;
              outline-offset: 1px;
            }

            @media (max-width: $screen-sm-max) {
              height: 40px;
              font-size: $MobileCopyRegular16;
              padding-left: 55px;
            }

            @media (min-width: $screen-md-min) {
              height: 53px;
            }

            @media (min-width: $screen-tab) {
              height: 62px;
            }

            &::placeholder {
              color: $medGray;
            }
          }

          .enter-btn {
            border: 0;
            outline: 0;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            background: transparent;
            display: none;

            &.enter-btn-active {
              display: block;
              &:focus {
                outline: $focus-outline;
              }
            }

            img {
              @media (max-width: $screen-sm-max) {
                width: 40px;
              }

              @media (min-width: $screen-md-min) {
                width: 53px;
              }

              @media (min-width: $screen-tab) {
                width: 60px;
              }
            }
          }
        }

        .cloes-auto-suggest {
          border: 0;
          outline: 0;
          width: 26px;
          height: 26px;
          padding: 5px;
          margin-left: 25px;
          background: url('assets/images/close_icon.png');
          background-repeat: no-repeat;
          background-size: contain;
          display: none;

          &.cloes-auto-suggest-active {
            display: inline-block;
            &:focus{
              outline: $focus-outline;
            }
          }

          @media (min-width: $screen-xs-min) {
            margin-top: 7px;
            margin-left: 15px;
          }

          @supports (-moz-appearance:none) {
            vertical-align: super;
          }

          @media screen and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {
            margin-top: 0;
            vertical-align: middle;
          }
        }
      }

      .header-menu-list {
        display: flex;
        justify-content: flex-end;
        height: 64px;
        padding: 0;
        position: absolute;
        right: 10px;
        text-align: right;
        width: calc(100% - 450px) !important;
        padding-right: 0;

        &.header-menu-list-hide {
          display: none;
        }

        @media (max-width: $screen-xl-min - 1) {
          width: calc(100% - 370px) !important;
        }

        @media (max-width: $screen-tab - 1) {
          display: none;
        }

        .menu-text {
          height: inherit;
          line-height: 1.5;
          width: 15%;
          position: relative;
          text-align: center;

          @media (max-width: $screen-xl-min - 1) {
            padding: 0;
            width: 16.6%;
          }

          &::after {
            border-right: 1px solid #b3b3b3;
            content: "";
            display: block;
            height: 50px;
            width: 1px;
            position: absolute;
            left: 100%;
            top: 7px;
          }

          &:last-child {
            &::after {
              border: 0;
            }
          }

          .link-attribute-class {
            height: inherit;
            width: 100%;
            display: table;
          }

          a {
            @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
            padding: 0 10px;
            position: relative;
            height: inherit;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            color: $colorGray;
            width: 100%;
            z-index: $zindex-fixed;

            &:hover,
            &:focus {
              text-decoration: underline;
              color: $primaryBlue;
              outline: $focus-outline;
            }

            @media (max-width: $screen-xl-min - 1) {
              padding: 0 7px;
            }
          }

          &.page-active {
            &::after {
              border-right: 0;
            }
          }

          a.page-active {
            &::after {
              content: '';
              width: 100%;
              height: 99px;
              background-color: #e3e1e1;
              position: absolute;
              top: -20px;
              left: 0;
              z-index: -1;
              border-right: 0;
            }
          }
        }
      }

      #megamenuModalCenter {
        @media (max-width: $screen-sm-max) {
          height: auto;
          box-shadow: $bookmark-dropdown;
        }

        .modal-dialog-centered {
          max-width: 1280px;
          max-height: 490px;

          @media (max-width: $screen-sm-max) {
            margin: 0;
            height: 100vh;
            max-height: 100%;
          }

          @media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
            margin: 0;
          }
        }

        .modal-content {
          border: 0;
          border-radius: 0;
          min-height: 490px;
          max-height: 490px;
          box-shadow: $megamenu-modal;
          overflow-y: auto;

          -ms-overflow-style: -ms-autohiding-scrollbar;
          -ms-overflow-x: hidden;

          @media screen and (max-width: $screen-sm-max) {
            position: absolute;
            top: 0;
            min-height: 100%;
            overflow-y: auto;
          }

          button.close {
            @include modalCloseBtn();
            &:focus {
              outline: $focus-outline;
            }
          }

          .modal-body {
            @media (max-width: $screen-md-max) {
              padding: 40px 0 0;
            }

            @media (min-width: $screen-md-min) {
              padding: 100px 33px 54px 56px;
            }

            >ul {
              margin: 0;

              >li {
                @media (min-width: $screen-md-min) {
                  width: 28%;
                  margin-right: 30px;
                  margin-bottom: 40px;
                  display: inline-block;
                }

                @media (min-width: $screen-lg-min) {
                  width: 27%;
                  margin-bottom: 50px;
                  margin-right: 50px;
                }

                @media (min-width: $screen-xl-min) {
                  width: 20%;
                  margin-bottom: 50px;
                  margin-right: 50px;
                }

                a {
                  font-family: $fontfamilyDefault;
                  font-size: $MegaMenuLink20;
                  color: $primaryBlue;
                  display: block;


                  @media (max-width: $screen-sm-max) {
                    padding: 32px 12px 10px 25px;
                    border-bottom: 2px solid $primaryBlue;
                  }

                  @media (min-width: $screen-md-min) {
                    padding: 10px 0 10px 10px;
                    border-bottom: 1px solid $primaryBlue;
                  }

                  &:hover,
                  &:focus {
                    text-decoration: underline;
                    
                    @media (min-width: $screen-md-min) {
                      background: $lightGrayOne;
                      border-radius: 10px;
                      border-bottom: 0;
                    }
                  }

                }
              }
            }

            ul.main-nav-links {
              display: none;
              background: $primaryBlue;

              @media (max-width: $screen-sm-max) {
                display: block;
                margin-top: 40px;
                padding: 5px 0;
              }

              li {
                a {
                  padding: 5px 10px;
                  color: $primaryWhite;
                }
              }
            }
          }
        }
      }
    }
  }
}
.empty-sticky-nav-wrap {
  height: 100px;
  @media (max-width: $screen-sm-max) {
    height: 60px;
  }
}
