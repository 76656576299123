.calender-view-main {
  .container {
    padding: 16px;
  }
  .calender-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $secondarBorderBottomGray;
    background-color: $primaryWhite;
    height: 70px;
    &.sticky-action {
      position: fixed;
      top: 60px;
      z-index: $zindex-overlay;
      width: 100%;
      left: 0;
      height: auto;
      padding: 0 13px 10px;
      box-shadow: $auto-suggest-modal;
      img {
        @media (max-width: $screen-sm-max) {
          display: none;
        }
      }
      h1 {
        @media (max-width: $screen-sm-max) {
          &:before {
            width: 19px;
            height: 19px;
            content: '';
            display: inline-block;
            position: absolute;
            background-image: url('assets/images/calendar-hero-small-mobile@2x.png');
            background-size: contain;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
          }
          padding-left: 30px;
          position: relative;
          @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
          line-height: 1.5;
          color: $primaryBlack;
          margin-top: 18px;
        }
      }
    }
    img {
      margin-right: 10px;
    }
    h1 {
      text-align: left;
      display: inline-block;
      margin: 0;
      vertical-align: bottom;
    }
    .button-wrap {
      display: inline-block;
      float: right;
    }
    .filter-button {
      margin-top: 5px;
      padding-right: 0;
      width: 65px;
      display: inline-block;
      text-decoration: none;
      @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
      color: $primaryBlue;
      padding-bottom: 0;
      .drop-down-icon {
        @include dropDownUpChevron();
        width: 8px;
        &:before {
          @include dropDownUpChevronBefore();
          color: $primaryBlue;
          background-image: url('assets/images/right-arrow.png');
          left: 0;
          top: 8px;
          width: 8px;
        }
      }
    }
  }
  .empty-calender-header {
    height: 70px;
  }
  .calendar-list-column {
    @include calendarEventsListingStyles();

    @media (max-width: $screen-md-max) {
      min-height: calc(50vh - 100px) !important;
    }

    .events-data {
      @media (max-width: $screen-sm-max) {
        width: 97%;
      }
    }
  }

  .filter-card {
    .calendar-filters {
      border-top: 1px solid $secondarBorderBottomGray;
      padding: 30px 16px 0;
      margin-top: 15px;
      max-width: 320px;
      margin: auto;
      .checkbox-list {
        @include customCheckbox();
        .us-check,
        .canada-check,
        .intl-check {
          padding-left: 35px;
        }
      }
      .radio-list {
        @include customRadioBtn();
        padding-bottom: 10px;
        .radio_active {
          padding: 5px 0 10px 35px;
        }
        input[type="radio"] {
          left: 0 !important;
        }
      }
      .checkbox-ul {
        padding-left: 35px;
      }
      label {
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
        input {
          cursor: pointer;
        }
      }
    }
    @include calendarDatepickerStyles();
  }
  .modal {
    @include calendarModalStyles();
  }

  @media (min-width: $screen-md-min) {
    .container {
      padding: 0 30px;
    }
    .calender-header {
      height: 100px;
      &.sticky-action {
        top: 80px;
        padding: 20px 30px;
      }
      img {
        height: 70px;
        width: 70px;
      }
      .filter-button {
        width: 65px;
        margin: 0 0 5px 0;
      }
      .print-button {
        width: 130px;
        margin-right: 12px;
      }
      .appendix-button {
        margin: 0 0 0 12px;
      }
    }
    .empty-calender-header {
      height: 100px;
    }
    .filter-card {
      .calendar-filters {
        .checkbox-list {
          .us-check,
          .canada-check,
          .intl-check {
            padding-left: 35px;
          }
        }
        .radio-list {
          .radio_active {
            padding: 5px 0 10px 35px;
          }
        }
        input[type=radio]:focus{
          + span.radio_active{
            outline: $focus-outline;
          }
        }
        input[type=checkbox]:focus{
          + span{
            outline: $focus-outline;
          }
        }
      }
    }
  }
  @media screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
    // https://gale43.atlassian.net/browse/BMOECON-441
    .row.calendar-row {
      .calendar-filter-column {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .calendar-list-column {
        flex: 0 0 70%;
        max-width: 70%;
        padding-left: 20px;
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .container {
      padding: 20px 40px;
    }
    .calender-header {
      margin-bottom: 40px;
      height: auto;
      .print-button {
        margin-left: 80px;
        width: 145px;
        &:focus {
          border: 0;
          outline: $focus-outline;
        }
      }
      .appendix-button {
        &:focus {
          border: 0;
          outline: $focus-outline;
        }
      }
      .button-wrap {
        float: none;
      }
    }
    .empty-calender-header {
      height: 0;
    }
    .filter-card {
      padding: 15px 15px 5px;
      border-radius: 5px;
      box-shadow: $calendar-filter-block;
      &.sticky-action {
        position: fixed;
        top: 125px;
        z-index: 1;
      }
      &.bottom-sticky-action {
        position: absolute;
        bottom: -90px;
      }
    }
    #sidebar {
      position: relative;
      width: 100%;
      padding: 0;
      .sidebar__inner {
        position: absolute;
        width: 100%;
        max-width: 320px !important;
      }
    }
  }
}

.calender-view-main .calendar-list-column .cbd-calendar-list {
  .cbd-data-wrap {
    .media-body {
      @media (max-width: $screen-sm-max) {
        margin-top: 10px;
      }
    }
  }
}
