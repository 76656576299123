.bmo-carousel {
  height: 200px;
  overflow: hidden;

  @media (max-width: $screen-sm-max) {
    margin-top: 5px;
  }

  .carousel-item {
    height: 200px;
    background: no-repeat;
    background-size: cover !important;
    background-position: center center !important;
    overflow-y: hidden;

    .carousel-caption {
      background-color: $negativeRed;
      width: 240px;
      height: 240px;
      border-radius: 120px;
      top: 50%;
      transform: translateY(-50%);
      left: -50px;
      border: .5px solid white;
      padding: 5px;
      background-clip: content-box;

      &.disable {
        background-color: transparent;
        border: none;
      }

      .carousel-caption-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 1.25rem 1.25rem 1.25rem 3.4375rem;
        text-align: left;
        font-family: $fontfamilyDefault;
        color: white;
        font-size: 1.6rem;

        p,
        a {
          @include bmoFont(1.25rem, $fontFamilyRegular);
        }

        a {
          font-weight: $fontFamilyMedium;
          cursor: pointer;
          &:hover,&:focus{
            text-decoration: underline!important;
            outline: $focus-outline;
          }
        }

        p {
          margin-bottom: 0.8125rem;
          font-size: 0.9rem;
        }

        @media screen and (max-width: $screen-sm-max) {
          p {
            font-size: 0.89rem !important;
            background: none !important;
          }

          a {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: $screen-md-min) {
    height: 323px;

    .carousel-item {
      height: 323px;

      .carousel-caption {
        width: 380px;
        height: 380px;
        border-radius: 190px;
        left: 2%;
        border: 1.5px solid white;
        padding: 8px;

        .carousel-caption-text {
          padding: 4.375rem;
          font-size: 0.775rem;
          p{
            font-size: 0.87rem;
          }
          a {
            color: #ffffff;
            &:hover {
              color: #ffffff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    height: 388px;

    .carousel-item {
      height: 388px;

      .carousel-caption {
        width: 440px;
        height: 440px;
        border-radius: 220px;
        left: 100px;
        border: 1.5px solid white;
        padding: 8px;

        .carousel-caption-text {
          padding: 5rem;
          font-size: 1.8rem;
          font-weight: 300;
          p{
            font-size: 0.97rem;
          }
        }
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 35px;
    height: 35px;
    opacity: 1;
    top: 45%;
    z-index: 10;
    @media screen and (max-width: $screen-sm-max) {
      width: 20px;
      height: 20px;
    }

    &:focus {
      outline: $focus-outline;
    }
  }

  .carousel-control-play,
  .carousel-control-pause {
    width: 25px;
    height: 25px;
    opacity: 1;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    margin-bottom: 5px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: $primaryWhite;
    text-align: center;
    transition: opacity .15s ease;
    right: 0px;
    @media screen and (min-width: 768px){
      right: 5px;
    }
    @media screen and (max-width: $screen-sm-max) {
      width: 15px;
      height: 15px;
    }

    &:focus {
      outline: $focus-outline;
    }
    
    .carousel-control-play-icon,
    .carousel-control-pause-icon {
      width: 25px;
      height: 25px;
      background: no-repeat 50%/100% 100%;
      @media screen and (max-width: $screen-sm-max) {
        width: 15px;
        height: 15px;
      }
    }
  }

  .carousel-control-prev {
    padding-left: 0px;
  }

  .carousel-control-next {
    padding-right: 0px;
  }

  .carousel-control-play {
    padding-right: 0px;
  }
  .carousel-control-pause {
    padding-right: 0px;
  }

  .carousel-indicators{
    width: 70px;
    margin: 10px auto;
    background: $primaryWhite;
    border-radius: 4px;
    box-shadow: 0px 2px 5px $medGrayTertiary;
    padding: 5px;
    @media screen and (max-width: $screen-sm-max) {
      width: 49px;
      height: 18px;
      padding: 2px;
    }
  }

  .carousel-indicators a {
    margin: 3px;
    width: 12px;
    border-radius: 12px;
    height: 12px;
    background-color: transparent;
    border: 1px solid $primaryBlue;
    &:focus{
      outline: $focus-outline;
    }
    @media screen and (max-width: $screen-sm-max) {
      width: 7px;
      height: 7px;
    }

    &.active {
      background-color: $primaryBlue;
      border-width: 1px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 35px;
      height: 35px;
      @media screen and (max-width: $screen-sm-max) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
