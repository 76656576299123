.videocast-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.video_cast_mega_wrap {
  .video_cast_heading_container {
    color: $colorGray;
    margin-top: 10px;
    p {
      font-size: $DesktopCopyRegular14;
      margin-bottom: 15px;
      text-align: left;
    }
    h3 {
      text-align: left;
    }
  }
  .video_cast_profile_container {
    margin-top: 12px;
    .video_cast_profile-desc {
      position: relative;
      border-bottom: 1px solid $secondarBorderBottomGray;
      width: 100%;
      .name {
        color: $primaryBlue;
        font-size: $DesktopCopyRegular14;
      }
      .position {
        color: $colorGray;
        line-height: 1.25;
        width: 80%;
      }
      .contact {
        margin-top: 20px;
        .mail-icon {
          width: 20px;
          height: 15px;
          margin-right: 20px;
        }
        .phone-icon {
          width: 18px;
          height: 18px;
        }
      }
      .arrow-icon {
        .arrow-down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .video_cast_video_container {
    margin-top: 20px;
    .bookmark-mail-wrap {
      img {
        width: 55px;
        height: 55px;
      }
    }
    .video-wrap {
      width: 100%;
      position: relative;
      box-shadow: 0 3px 13px 0 $secondaryBoxShadow;
      video,
      iframe {
        height: 100%;
        @media (max-width: $screen-sm-max) {
          width: calc(100vw - 30px);
        }
      }
      .youtube-wrap {
        position: relative;
        padding-bottom: 50%;
        padding-top: 25px;
        height: 0;
        #video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      img.video-overlay-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .plyr__control--overlaid {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid $primaryBlue;
        background-color: $primaryWhite;
        padding: 0px;
        svg {
          margin: auto;
          color: $primaryBlue;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  .video_cast_desc_container {
    a {
      font-weight: $fontFamilyLight;
    }
    .video-title-bottom,
    .see-videocast-slides {
      color: $primaryBlue;
    }
    .video-title-bottom {
      margin: 25px 0 15px 0;
      line-height: 1.13;
      span {
        opacity: 0.7;
      }
    }
    .see-videocast-slides {
      cursor: pointer;
      line-height: 1.88;
      margin-bottom: 15px;
    }
  }
  @media(min-width: $screen-md-min) {
    padding: 0 30px;
    .video_cast_profile_container {
      margin-top: 28px;
      .video_cast_profile-img {
        width: 80px;
        height: 80px;
      }
      .video_cast_profile-desc {
        border: none;
        .position {
          width: 100%;
        }
        .contact {
          line-height: 0.8;
          margin-top: 15px;
        }
      }
    }
    .video_cast_video_container {
      margin-top: 23px;
      .bookmark-mail-wrap {
        margin: 0 30px 0 18px;
        img {
          width: 55px;
          height: 55px;
          cursor: pointer;
        }
        .bookmark-icon {
          margin-bottom: 35px;
        }
      }
      .video-wrap {
        video {
          width: 100%;
          height: 100%;
        }
        iframe {
          width: calc(100%);
        }
        .plyr__control--overlaid {
            width: 110px;
            height: 110px;
            svg {
              transform: scale(2);
            }
        }
        img {
          width: 110px;
          height: 110px;
        }
      }
    }
    .video_cast_desc_container {
      .video-title-bottom {
        margin: 30px 0 15px 0;
        line-height: 1.88;
      }
    }
  }
  @media(min-width: $screen-lg-min) {
    padding: 0 40px;
    .video_cast_video_container {
      margin-top: 45px;
      .bookmark-mail-wrap {
        margin: 0 75px 0 7px;
      }
      .video-wrap {
        width: 100%;
        margin-right: 135px;
      }
    }
    .video_cast_desc_container {
      margin-left: 135px;
      .video-title-bottom {
        margin: 30px 0 15px 0;
        line-height: 1.88;
      }
    }
  }
}
   