/* Circle Component */
.circle-component-wrap {
  .circle-component-container {
    @media (max-width: $screen-sm-max) {
      padding: 24px 20px 0;
    }

    @media (min-width: $screen-md-min) {
      padding: 52px 20px 0;
    }

    @media (min-width: $screen-lg-min) {
      padding: 50px 80px 0;
    }

    .row {
      .col-12 {
        h1 {
          color: $secondaryGray;

          @media (max-width: $screen-sm-max) {
            @include bmoFont($DesktopCopyRegular30, $fontFamilyLight);
          }

          @media (min-width: $screen-md-min) {
            @include bmoFont($DesktopCopyRegular50, $fontFamilyLight);
            margin-bottom: 27px;
          }

          @media (min-width: $screen-lg-min) {
            margin-bottom: 24px;
          }
        }

        p {
          margin: 0 auto;
          @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
          color: $colorBlack;
          line-height: 1.44;

          @media (min-width: $screen-md-min) {
            @include bmoFont($DesktopCopyRegular25, $fontFamilyLight);
            color: $colorBlack;
            line-height: 1.36;
            max-width: 724px;
          }

          @media (min-width: $screen-xl-min) {
            max-width: 1121px;
          }
        }
      }

      /* Recent releases forecast */
      &.forecast {
        margin: 16px 0;

        @media (min-width: $screen-md-min) {
          margin-top: 3px;
        }

        @media (min-width: $screen-lg-min) {
          margin-top: 16px;
        }

        .circle-component-links {
          padding-bottom: 25px;

          @media (max-width: $screen-md-max) {
            padding-bottom: 15px;
          }

          @media (max-width: $screen-tab) {
            width: 100%;
          }

          img {
            @media (min-width: $screen-md-min) {
              width: 60%;
              height: 100px;
              object-fit: contain;
            }
          }

          span {
            @media (max-width: $screen-sm-max) {
              @include bmoFont($DesktopCopyRegular18, $fontFamilyLight);
            }

            @media (min-width: $screen-md-min) {
              @include bmoFont($DesktopCopyRegular20, $fontFamilyLight);
            }

            @media (max-width: $screen-tab) {
              width: 100%;
            }
          }
        }

        .column {
          @media (max-width: $screen-md-min) {
            padding: 0 !important;
          }

          @media (min-width: $screen-md-min) {
            margin: 0 12px;
          }
        }
      }
    }

    .circle-component-block {
      justify-content: center;
      margin-top: 60px;

      @media (max-width: $screen-sm-max) {
        margin-top: 25px;
      }

      @media (min-width: $screen-windows-md) {
        padding: 0 110px;
      }

      @media (min-width: $screen-windows-lg) {
        padding: 0 150px;
      }
      .column {
        @media (max-width: $screen-sm-max) {
          width: 50%;
          max-width: 50%;
        }
      }

      .circle-component-links {
        display: inline-block;
        @include bmoFont($DesktopCopyRegular25, $fontFamilyRegular);
        color: $primaryBlue;
        margin-bottom: 66px;

        @media (max-width: $screen-sm-max) {
          @include bmoFont($MobileCopyRegular16, $fontFamilyLight);
          margin-bottom: 25px;
        }

        img {
          @media (max-width: $screen-sm-max) {
            width: 98px;
            margin-bottom: 10px;
          }

          width: 163px;
          padding-bottom: 15px;
          transition: .3s ease-in-out;
        }

        span {
          display: block;
          width: 98px;
          margin: 0 auto;
          transition: .3s ease-in-out;

          @media (min-width: $screen-md-min) {
            width: 171px;
          }
        }

        &:hover,
        &:focus {
          text-decoration: none;
          outline: $focus-outline;

          img {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
          }

          span {
            text-decoration: underline;
          }
        }

        .column {
          @media (min-width: $screen-md-min) {
            padding: 0;
          }
        }
      }
    }
  }
  &.no-horizontal-line {
    .horizontal-divider-wrap {
      display: none;
    }
  }
}
