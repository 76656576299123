.page-title-section.page-title-recent-releases {
  .page-title-header {
    padding: 16px 16px 0;
  }
}

.page-title-section.page-title-our-economists {
  .page-title-header {
    padding: 16px;
  }
}

.page-title-section {
  .container {
    padding: 0;
  }

  .page-title-header {
    padding: 16px 16px 25px;

    h1 {
      display: inline-block;
      vertical-align: bottom;
      margin: 0 0 2px 5px;
      color: $secondaryGray;
    }
  }

  @media (min-width: $screen-md-min) {
    .page-title-header {
      padding: 22px 30px 40px !important;

      img {
        width: 70px;
        height: 70px;
      }

      h1 {
        margin: 0 0 10px 10px;
        line-height: 1;
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .page-title-header {
      padding: 46px 40px 38px !important;

      h1 {
        margin-left: 13px;
      }
    }
  }
}
