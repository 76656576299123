/* nav Bar */

/* Component Specific Mixins and Variables */

$BookMarkMobile12: 0.75em; // 12px
$BookMarkMobile14: 0.88em; // 14px

@mixin navBarFontRegular() {
  font-family: $fontfamilyDefault;
  font-size: $DesktopCopyRegular14;
  font-weight: $fontFamilyRegular;
}

@mixin navBarFontLight() {
  @include navBarFontRegular();
  font-weight: $fontFamilyLight;
}

@mixin borderBottomRadius() {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.top-nav-wrap {
  background: $primaryBlue;

  .top-nav-bar {
    @media (max-width: $screen-sm-max) {
      height: 50px;
      padding: 5px 15px;
    }

    @media (min-width: $screen-md-min) {
      height: 61px;
      padding: 13px 30px 10px;
    }

    @media (min-width: $screen-lg-min) {
      padding: 16px 40px;
    }

    .top-nav-bar-common {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div:first-child {
        display: flex;
      }

      /* Hambergur icon */

      .megamenu-hamburger-icon {
        border: 0;
        outline: none;
        padding: 7px 0;

        @media (min-width: $screen-md-min) {
          margin: 15px 0;
          display: none;
        }

        img {
          width: 30px;
          border: none;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .brand-logo {
        display: inline-block;
        @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);

        span {
          margin-left: 11px;
          color: $primaryWhite;
          vertical-align: middle;

          @media (max-width: $screen-sm-max) {
            margin-left: 8px;
          }
        }

        &:focus {
          outline: 2px solid $primaryWhite;
        }

        &:hover {
          text-decoration: none;
        }

        @media (max-width: $screen-sm-max) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 15px;
        }


        @media (min-width: $screen-lg-min) {
          margin: 0;
        }

        img {
          height: 35px;

          @media (max-width: $screen-sm-max) {
            height: 25px;
          }
        }
      }

      .top-nav-bar-links {
        margin: 0;
        padding: 0;
        margin-left: 37px;
        display: flex;
        align-items: center;

        @media (max-width: $screen-sm-max) {
          display: none;
        }

        li {
          list-style: none;
          display: inline-block;

          a {
            text-decoration: none;
            color: $primaryWhite;
            font-family: $fontfamilyDefault;
            font-size: 15px;
            font-weight: $fontFamilyMedium;
            text-align: center;
            display: block;
            padding: 0;
            margin-right: 28px;

            &:focus {
              outline: 2px solid $primaryWhite;
            }
            &:hover {
              border-bottom: 1px solid $primaryWhite;
            }
          }
        }
      }

      .bookmark-count-wrap {
        margin-top: -11px;

        @media (max-width: $screen-sm-max) {
          position: static;
        }

        .bookmark-count-icon {
          display: block;
          width: 30px;
          height: 28px;
          position: relative;
          background-image: url('assets/images/Bookmark_Outline_2x.png');
          background-repeat: no-repeat;
          background-size: contain;
          margin-top: 2px;

          @media (max-width: $screen-sm-max) {
            width: 20px;
            height: 20px;
            margin-top: 10px;
          }

          @media (min-width: $screen-md-min) {
            margin-top: 8px;
          }

          @media (min-width: $screen-lg-min) {
            margin-top: 4px;
          }

          &:focus {
            outline: 2px solid $primaryWhite;
          }

          &.dropdown-toggle {
            &::after {
              border: 0;
            }
          }
        }

        .bookmark-count-icon[aria-expanded="true"] {
          background-image: url('assets/images/Bookmark_Icon_SolidWhite2x.png');

          &::after {
            position: absolute;
            display: inline-block;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 14px solid $primaryWhite;
            right: 5px;
            bottom: -32px;
            content: '';
            visibility: visible;

            @media (max-width: $screen-sm-max) {
              right: -2px;
              bottom: -19px;
            }

            @media (min-width: $screen-md-min) {
              bottom: -18px;
            }

            @media (min-width: $screen-lg-min) {
              bottom: -15px;
            }
          }
        }

        .bookmark-count {
          border-radius: 50%;
          background: $negativeRed;
          color: $primaryWhite;
          font-family: $fontfamilyDefault;
          font-size: $DesktopCopyRegular16;
          font-weight: $fontFamilyRegular;
          position: absolute;
          top: -10px;
          right: 0;

          @media (max-width: $screen-sm-max) {
            font-size: $BookMarkMobile14;
            top: -6px;
            right: -2px;
          }

          &.bookmark-count-hide {
            display: none;
          }

        }

        .dropdown-menu {
          &.bookmark-notification {
            width: 320px;
            min-height: 240px;
            max-height: 480px;
            overflow: auto;
            border: 0;
            margin: 0;
            box-shadow: $bookmark-dropdown;
            background-color: $primaryWhite;
            padding: 20px 22px 13px 25px;

            -ms-overflow-style: -ms-autohiding-scrollbar;
            -ms-overflow-x: hidden;

            @include borderBottomRadius();
            z-index: $zindex-overlay;
            &:focus{
              outline: $focus-outline;
            }

            @media (max-width: $screen-sm-max) {
              width: 100%;
              left: 0;
              top: 50px;
            }

            @media (min-width: $screen-md-min) {
              left: -235px;
              top: 68px;
            }

            @media (min-width: $screen-lg-min) {
              top: 42px;
              left: -75px;
              transform: translateX(-50%);
            }

            .dropdown-item {
              padding: 0;
            }

            .bookmark-info {
              display: none;

              p {
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                color: $secondaryGray;
                margin-bottom: 20px;

                &:last-child {
                  margin: 0;
                }
              }

              &.bookmark-info-active {
                display: block;
              }
            }

            ul {
              padding: 0;
              margin: 0;

              li {
                list-style: none;
                padding: 7px 0;

                .media-body {
                  .media-date-name-block {
                    .media-date {
                      @include navBarFontRegular();
                      color: $colorGray;
                      margin-right: 20px;
                    }

                    .media-name {
                      @include navBarFontRegular();
                      color: $primaryBlue;
                      &:hover{
                        text-decoration: underline!important;
                      }
                      &:focus{
                        text-decoration: underline!important;
                        outline: $focus-outline;
                      }
                    }

                    .remove-bookmarked-item {
                      color: $medGray;
                      opacity: 1;

                      &:hover {
                        color: $medGray;
                        opacity: 1;
                      }
                      &:focus{
                        outline: $focus-outline;
                      }
                    }
                  }

                  .media-paragraph {
                    @include navBarFontLight();
                    margin: 0;
                    line-height: 1.29;
                    color: $primaryBlue;
                    padding: 0;
                    margin-top: 7px;
                    display: block;
                    &:hover{
                      text-decoration: underline!important;
                    }
                    &:focus{
                      text-decoration: underline!important;
                      outline: $focus-outline;
                    }
                  }
                }
              }

              &.media-list-hide {
                display: none;
              }
            }
          }
        }
      }

      // Mega Menu modal

      #megamenuModal {
        @media (max-width: $screen-sm-max) {
          position: fixed;
          height: auto;
          box-shadow: $bookmark-dropdown;
        }

        .modal-dialog-centered {
          overflow-y: auto;

          @media (max-width: $screen-sm-max) {
            margin: 0;
            height: 100vh;
            max-width: 100%;
            max-height: 100%;
          }

          @media (min-width: $screen-md-min) {
            display: none;
          }
        }

        .modal-content {
          border: 0;
          border-radius: 0;
          min-height: 490px;
          max-height: 490px;
          box-shadow: $megamenu-modal;
          overflow-y: auto;

          @media (max-width: $screen-sm-max) {
            position: absolute;
            top: 0;
            min-height: 100%;
          }

          button.close {
            @include modalCloseBtn;
          }

          .modal-body {
            @media (max-width: $screen-md-max) {
              padding: 0;
              margin: 40px 0 0;
            }

            @media (min-width: $screen-md-min) {
              padding: 100px 33px 54px 56px;
            }

            > ul {
              margin: 0;

              >li {
                @media (min-width: $screen-md-min) {
                  width: 28%;
                  margin-right: 30px;
                  margin-bottom: 40px;
                  display: inline-block;
                }

                @media (min-width: $screen-lg-min) {
                  width: 27%;
                  margin-bottom: 50px;
                  margin-right: 50px;
                }

                @media (min-width: $screen-xl-min) {
                  width: 20%;
                  margin-bottom: 50px;
                  margin-right: 50px;
                }

                a {
                  font-family: $fontfamilyDefault;
                  font-size: $MegaMenuLink20;
                  color: $primaryBlue;
                  display: block;


                  @media (max-width: $screen-sm-max) {
                    padding: 32px 12px 10px 25px;
                    border-bottom: 2px solid $primaryBlue;
                  }

                  @media (min-width: $screen-md-min) {
                    padding: 10px 0 10px 10px;
                    border-bottom: 1px solid $primaryBlue;
                  }

                  &:hover {
                    text-decoration: none;

                    @media (min-width: $screen-md-min) {
                      background: $lightGrayOne;
                      border-radius: 10px;
                      border-bottom: 0;
                    }
                  }

                }
              }
            }

            ul.main-nav-links {
              display: none;
              background: $primaryBlue;

              @media (max-width: $screen-sm-max) {
                display: block;
                margin-top: 40px;
                padding: 5px 0;
                @media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
                  padding: 5px 0 70px;
                }
                @media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
                  padding: 5px 0 90px;
                }
                @media only screen and (min-width: 414px) and (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
                  padding: 5px 0 90px;
                }
                @media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
                  padding: 5px 0 120px;
                }
              }

              li {
                a {
                  padding: 5px 10px;
                  color: $primaryWhite;
                }
              }
            }
          }
        }
      }

    }
  }
}


.media-list-bookmark {
  padding: 30px 50px;
  border: 1px solid $medGray;
  margin-bottom: 50px;

  .media {
    padding: 10px 20px;
  }

  .book-mark-wrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $primaryBlue;
    background: $primaryBlue;

    .saveBookMark {
      display: block;
      width: 30px;
      height: 28px;
      position: relative;
      background-image: url('assets/images/bookmar_path@2x.png');
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 9px;
      margin-top: 5px;

      &.bookmarked {
        background-image: url('assets/images/Bookmark_Icon_SolidWhite2x.png');
      }
    }
  }
}
