
.videocast-overlay-wrap {
  overflow: hidden;
  height: 101%;
  .modal-dialog {
    max-width: $screen-windows-lg;
    width: 100%;
    margin: 0;
    background-color: $primaryWhite;
    .videocast-content {
      height: 100%;
      border: 0;
      overflow-y: scroll;
      .close {
        width: 20px;
        height: 20px;
      }
      .videocast-loader.translate-center {
        left: auto;
        right: 50%;
        transform: translateX(50%);
      }
      .videocast-overlay-container {
        .videocast-overlay {
          margin-top: 62px;
          h3 {
            margin-bottom: 12px;
            font-family: $fontFamilyRegular;
          }
          .economicist-name {
            @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
            color: $primaryBlue;
            margin-bottom: 15px;
          }
          .economicist-image {
            width: 80px;
            height: 80px;
          }
        }
        // Here important is being because unitegallery plugin gives inline styling
        #gallery {
          margin: 0 auto;
          .ug-item-wrapper img{
            width: 100% !important;
            height: 100% !important;
            top: 0 !important;
            left: 0 !important;
          }
          .ug-slider-control.ug-zoompanel {
            display: none;
          }
          .ug-textpanel-description {
            text-align: center !important;
          }
          .ug-textpanel-textwrapper {
            left: 0 !important;
          }
          .thumb-text {
            position: absolute;
            width: 100%;
            z-index: 100;
            top: 90px;
            color: $primaryBlue;
          }
          .ug-thumbs-strip {
            overflow-y: visible !important;
            height: 125px !important;
          }
          .ug-thumb-wrapper.ug-thumb-generated {
            height: 125px !important;
          }
          .ug-thumb-wrapper.ug-thumb-generated img {
            height : 87px !important;
          }
          .ug-strip-arrow {
            height : 87px !important;
          }
          .ug-strip-arrow-left .ug-strip-arrow-tip, .ug-slider-control.ug-arrow-left {
            background-image: url('assets/images/left-arrow.png');
            background-position: 0 65%;
          }
          .ug-strip-arrow-right .ug-strip-arrow-tip, .ug-slider-control.ug-arrow-right {
            background-image: url('assets/images/right-arrow.png');
            background-position: 100% 65%;
          }
          .ug-strip-arrow-tip {
            height: 20px;
            width: 20px;
            background-size: 10px;
          }
        }
      }
    }
    @media (max-width: $screen-sm-max) {
      height: 100%;
      .videocast-content {
        .videocast-overlay-container { 
          #gallery {
            margin: 0 auto 50px;
            .ug-textpanel {
              background-color: $primaryWhite;
              width: 90% !important;
              left: 5% !important;
              right: 5% !important;
              top: -30px !important; // -ve sign used to move text-box up
              .ug-textpanel-textwrapper {
                width: 100% !important;
                .ug-textpanel-description {
                  top: 10px !important;
                  width: 100% !important;
                }
              }
            }
            .ug-slider-wrapper {
              position: static !important;
              overflow: hidden;
              .ug-slider-inner {
                height: inherit !important;
                .ug-slide-wrapper {
                  height: inherit !important;
                  .ug-item-wrapper {
                    height: inherit !important;
                  }
                }
              }
            }
            .ug-slider-control {
              width: 10%;
              height: 35px;
              background-color: $primaryWhite;
              background-size: 10px;
              &.ug-arrow-left {
                left: 0 !important;
              }
              &.ug-arrow-right {
                right: 0 !important;
                left: auto !important;
              }
            }
          }
        }
      }
    }
    @media (min-width: $screen-md-min) {
      .videocast-content {
        .videocast-overlay-container {
          padding: 0 30px; 
          height: 100vh;
          .videocast-overlay {
            margin-top: 60px;
            margin-bottom: 45px;
            .publications-date{
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              color: $colorGray;
              margin-bottom: 15px;
            }
            h3 {
              margin-bottom: 35px;
            }
            .economicist-block {
              width: 75%;
            }
            .economicist-name {
              margin-left: 20px;
              margin-bottom: 0;
              line-height: 20px;
              span {
                color: $colorGray;
                width: 70%;
                &.contact-icon {
                  margin-top: 20px;
                  img{
                    width: 20px;
                    margin-right: 30px;
                  }
                }
              }
            }
          }
          #gallery {
            .ug-slider-control {
              display: none;
            }
            .ug-arrow-left.ug-skin-default, .ug-arrow-right.ug-skin-default {
              display: none;
            }
            .ug-textpanel {
              z-index: $zindex-sticky !important;
              top: 43px !important;
            }
          }
        }
      }
    }
    @media (min-width: $screen-lg-min) {
      margin: 100px auto;
      .videocast-content {
        .videocast-overlay-container {
          padding: 0 65px; 
          height: 100%;
          .videocast-overlay {
            .economicist-block {
              float: right;
            }
            .economicist-name{
              span {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
