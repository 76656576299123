/* Common Global scss.*/
.primary-bmo-blue {
  background: $primaryBlue;
}

.text-color-white {
  color: $primaryWhite;
  text-decoration: none;
}

.narrow-wrap {
  white-space: nowrap;
}

.btn.btn-outline-primary {
  width: 170px;
  height: 45px;
  border-radius: 20.6px;
  border: solid 1px $primaryBlue;
  color: $primaryBlue;
  @include bmoFont(1rem, $fontFamilyRegular);
  margin: 1rem;

  &:hover:not(:disabled) {
    border: solid 2px $primaryBlue;
    font-weight: $fontFamilyMedium;
    background: transparent;
  }
}

/************* Accessibility/ screen reader  *************/
#page a.skip-to-homepage {
  z-index: -999;
  overflow: hidden;
  color: transparent;
  position: absolute;
  top: -100px;
}

/************* Horizontal line css  *************/
.horizontal-divider-wrap {
  @include horizontalLine(calc(100% - (15px * 2)));

  @media (min-width: $screen-md-min) {
    width: calc(100% - (30px * 2));
  }

  @media (min-width: $screen-lg-min) {
    max-width: calc(#{$screen-windows-lg} - (40px * 2));
    margin: 0 auto;
    width: calc(100% - (40px * 2));
  }
}

/******************** custom checkbox  *******************/

@mixin customCheckbox() {

  input[type="checkbox"]:checked,
  input[type="checkbox"]:not(:checked) {
    position: absolute;
    left: 6px;
    top: 8px;
  }

  input[type="checkbox"]:checked+span,
  input[type="checkbox"]:not(:checked)+span {
    position: relative;
    padding-top: 5px;
    cursor: pointer;
    display: inline-block;
    @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
    color: $colorGray;

    @media (min-width: $screen-md-min) {
      padding-left: 30px;
    }

    @media (min-width: $screen-xl-min) {
      padding-left: 36px;
    }
  }

  input[type="checkbox"]:checked+span::before,
  input[type="checkbox"]:not(:checked)+span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 25px;
    height: 25px;
    border: 1px solid $medGray;
    background-color: $primaryWhite;
    margin: 0;
    border-radius: 2px;
  }

  input[type="checkbox"]:checked+span::after,
  input[type="checkbox"]:not(:checked)+span::after {
    content: '';
    width: 25px;
    height: 25px;
    background: $primaryBlue;
    position: absolute;
    top: 3px;
    left: 0;
    border-radius: 2px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    &:hover {
      border: 1px solid red;
    }
  }

  input[type="checkbox"]:not(:checked)+span::after {
    opacity: 0;
  }

  input[type="checkbox"]:checked+span::after {
    opacity: 1 !important;
  }
}


/******************** custom Radio button  *******************/

@mixin customRadioBtn() {

  input[type="radio"]:checked,
  input[type="radio"]:not(:checked) {
    opacity: 0;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 45px;
    z-index: $zindex-sticky;
  }

  input[type="radio"]:checked+label,
  input[type="radio"]:not(:checked)+label {
    position: relative;
    padding-top: 5px;
    @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
    color: $colorGray;

    @media (min-width: $screen-md-min) {
      padding-left: 30px;
    }

    @media (min-width: $screen-xl-min) {
      padding-left: 36px;
    }
  }

  input[type="radio"]:checked+span::before,
  input[type="radio"]:not(:checked)+span::before {
    cursor: pointer;
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 24px;
    height: 24px;
    border: 1px solid $primaryBlue;
    background-color: $primaryWhite;
    margin: 0;
    border-radius: 50%;
  }

  input[type="radio"]:checked+span::after,
  input[type="radio"]:not(:checked)+span::after {
    cursor: pointer;
    content: '';
    width: 18px;
    height: 18px;
    background: $primaryBlue;
    position: absolute;
    top: 6px;
    left: 3px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type="radio"]:not(:checked)+span::after {
    opacity: 0;
  }

  input[type="radio"]:checked+span::after {
    opacity: 1 !important;
  }
}

/********************** Tooltip **********************/
// usage <div class="custom-tooltip right/left">[content]</div>
// make sure the parent its wrapped is relative.
// Hide and show logic needs to implemented customly just make display block
// refer Publication details.
.custom-tooltip {
  display: none;
  position: absolute;
  top: 50%;
  left: 55px;
  transform: translate(0, -50%);
  background-color: $primaryWhite;
  z-index: $zindex-sticky;
  border-radius: 5px;
  box-shadow: $bookmark-dropdown;
  margin-left: 10px;
  border: 1px solid $secondaryBorderGray;
  padding: 15px;
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    z-index: $zindex-sticky;
    box-shadow: -1px 1px 0 0 $secondary-boxshadow-color;
    width: 15px;
    height: 15px;
    content: '';
    background-color: $primaryWhite;
    display: none;
  }
  &:before {
    left: -8px;
    transform: translate(0, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(0, -50%) rotate(225deg);
    right: -8px;
  }
  &.left {
    left:0%;
    top:50%;
    margin-left: -8px;
    transform: translateX(-100%) translateY(-50%);
    &:after {
      display: block;
    }
  }
  &.right {
    &:before {
      display: block;
    }
  }
}

/******************** Load more publication btn override  *******************/
@mixin loadMorePublicationBtn() {
  margin: 0 auto;
  display: block;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 16px;
}

script.card {
  display: none;
}
