.auto-suggest-search {
  position: fixed;
  z-index: $zindex-sticky;
  top: 110px;
  width: 100%;
  display: block;
  height: calc(100vh - 165px);
  overflow: auto;

  &.sticky-nav-fixed {
    top: 60px;
    height: calc(100vh - 60px);
  }

  .container {
    box-shadow: $auto-suggest-modal;
    border-top: solid 2px $secondaryBorderPublication;
    width: 99%;
    &:focus{
      outline: $focus-outline;
    }
  }

  .result-header {
    .heading {
      @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
      color: $colorGray;
      display: inline-block;
      padding-bottom: 5px;
    }

    .show-less-more-toggle {
      float: right;

      .toggle-text {
        @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
        color: $primaryBlue;
        margin-right: 10px;
      }

      .drop-down-icon {
        @include dropDownUpChevron();

        &.down {
          &:before {
            top: 5px;
            transform: rotate(0);
          }
        }

        &:before {
          @include dropDownUpChevronBefore();
          background-image: url('assets/images/blue-chevron.png');
          color: $primaryBlue;
          left: -6px;
          margin-top: 5px;
          transform: rotate(-180deg);
        }
      }
    }
  }

  hr {
    width: 100%;
    margin: 0 15px;
    border-color: $secondaryBorderLineGray;
  }

  .results-container {
    .result-wrap {
      padding: 5px 0;
      a{
        color: #056fad !important;
        display: inline-block;
        &:focus{
          outline: $focus-outline;
        }
      }
    }

    .date {
      @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
      color: $primaryBlack;
    }

    .text,
    .light-text {
      @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
      color: $secondaryAccessibleBlue !important;
    }

    .light-text {
      font-weight: $fontFamilyLight;
    }

    .media {
      padding: 5px 0;
      cursor: pointer;

      .flag {
        object-fit: cover;
        border: 1px solid $calendarDisbaledGrey;
      }
      .flag[title="international"] {
        border: 0;
      }
      @include internationFlagBorder();
      .cal-text {
        color: $primaryBlack;
      }
    }
  }

  .see-all-anchor{
    display: inline-block;
    &:focus{
      outline: $focus-outline;
    }
  }

  .see-all-text {
    @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
    color: $primaryBlue;
    padding-top: 5px;
  }

  .auto-suggest-body,
  .auto-suggest-footer {
    .col {
      padding: 15px;
    }
    .no-match-found {
      @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
      color: $primaryBlack;
    }
  }

  .auto-suggest-body {
    background-color: $primaryWhite;
  }

  .auto-suggest-footer {
    .col {
      .result-wrap {
        padding: 2px 0
      }
    }

    .most-often-search,
    .recent-search {
      background-color: $lightGrayOne;
    }

    .recent-search {
      padding-bottom: 0;

      hr {
        margin: 15px 0;
      }
    }

    .most-often-search {
      padding-top: 0;
    }

    .featured-search {
      background-color: $primaryBlue;

      .heading,
      .text,
      .see-all-text {
        color: $primaryWhite;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .calendar-search-results {
      .media:nth-child(n+5) {
        display: none;
      }

      .results-container.show-less {
        .media:nth-child(n+2) {
          display: none;
        }
      }
    }

    .recent-search-results-container,
    .publication-search-results {
      .result-wrap:nth-child(n+4) {
        display: none;
      }

      .results-container.show-less {
        .result-wrap:nth-child(n+2) {
          display: none;
        }
      }
    }

    .most-often-results-container,
    .featured-results-container {
      .result-wrap:nth-child(n+3) {
        display: none;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    top: 160px;
    height: calc(100vh - 160px);
    z-index: $zindex-fixed;

    &.sticky-nav-fixed {
      top: 100px;
      height: calc(100vh - 100px);
    }

    hr {
      margin: 0;
    }

    .auto-suggest-body,
    .auto-suggest-footer {
      .col {
        padding: 15px 40px;
      }

      .no-match-found {
        font-size: $DesktopCopyRegular16;
      }
    }

    .auto-suggest-footer {
      .col {
        padding: 25px 40px;

        .result-wrap {
          padding: 5px 0;
        }
      }

      .recent-search:after {
        height: calc(100% - 50px);
        display: block;
        position: absolute;
        width: 1px;
        right: 0;
        top: 50%;
        background: $secondaryBorderLineGray;
        transform: translate(0, -50%);
        content: '';
      }
    }

    .auto-suggest-body {
      .result-wrap {
        padding: 7px 0;
      }

      .results-container {
        padding-top: 5px;
      }

      .download-ics {
        margin-top: 5px;
      }
    }

    .results-container {
      .date {
        font-size: $DesktopCopyRegular16;
      }

      .text,
      .light-text {
        font-size: $DesktopCopyRegular16;
      }
    }

    .result-header {
      .heading {
        font-size: $DesktopCopyRegular16;
      }
    }

    .see-all-text {
      font-size: $DesktopCopyRegular16;
    }

    .recent-search-results-container {
      .result-wrap {
        &:nth-child(n+4) {
          display: none;
        }
      }
    }

    .most-often-results-container,
    .featured-results-container {
      .result-wrap {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }

  }

  @media (max-width: $screen-md-max) {
    .calendar-results-container {
      .media {
        &:nth-child(n+6) {
          display: none;
        }
      }
    }

    .publication-search-results {
      .result-wrap {
        &:nth-child(n+5) {
          display: none;
        }
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    max-width: $screen-windows-lg;
    left: 50%;
    transform: translate(-50%, 0);
    top: 161px;
    height: calc(100vh - 148px);

    .publication-search-results {
      .results-container {
        column-count: 2;
      }

      .result-wrap {
        display: inline-block;
        width: 100%;
        padding: 7px 40px 7px 0;

        &:nth-child(n+6) {
          display: none;
        }
      }
    }

    .result-header {
      .heading {
        font-size: $DesktopCopyRegular20;
      }
    }

    .publication-search-results,
    .calendar-search-results {
      .results-container {
        padding-top: 10px;

        .media {
          .download-ics {
            display: none;
          }

          &:hover {
            .download-ics {
              display: block;
            }
          }
        }
      }
    }

    .auto-suggest-body {
      padding-bottom: 20px;
    }
  }

  .calendar-results-container {
    .media {
      &:nth-child(n+4) {
        display: none;
      }
      .media-body {
        &:hover {
          .date,
          .text.cal-text {
            color: $primaryBlue;
          }
        }
      }
    }
  }

  .publication-search-results {
    .result-wrap {
      &:nth-child(n+7) {
        display: none;
      }
    }
  }
}
.auto-suggest-search a div{
  &:hover{
    text-decoration: underline!important;
  }
}
