.calender-strip {
  .container {
    padding: 16px;
  }
  .calender-strip-header {
    position: relative;
    .filter-button {
      position: absolute;
      display: inline-block;
      text-decoration: none;
      right: 0;
      z-index: $zindex-basic;
      @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
      color: $primaryBlue;
      margin-top: 15px;
      width: 65px;
      text-align: right;
      padding-right: 0;
      .drop-down-icon {
        @include dropDownUpChevron();
        width: 8px;
        height: 19px;
        &:before {
          @include dropDownUpChevronBefore();
          color: $primaryBlue;
          background-image: url('assets/images/right-arrow.png');
          left: 0;
          top: 8px;
          width: 8px;
        }
      }
    }
  }
  .calender-strip-row {
    width: 100%;
    margin: 0;
  }
  .calender-strip-country-filter {
    margin: 15px 0;
    text-align: center;
    .country-image-wrap {
      display: inline-block;
      margin: 0 10px;
      opacity: 0.5;
      padding: 0;
      border-radius: 0;
      &.active {
        border-bottom: 2px solid $primaryBlue;
        opacity: 1;
      }
      img {
        margin-bottom: 10px;
        margin-right: 0;
        border: 1px solid $calendarDisbaledGrey;
      }
      @include internationFlagBorder();
    }
  }
  .calendar-strip-list-column {
    padding: 0;
    @include calendarEventsListingStyles();
    .calendar-date-wrap {
      border-bottom: none;
      h2 {
        margin-top: 0;
      }
    }
  }
  .filter-card {
    @include calendarDatepickerStyles();
  }
  .modal {
    @include calendarModalStyles();
    .modal-dialog {
      .modal-content {
        height: auto;
        padding-bottom: 45px;
      }
      .close-button-container {
        .close-button {
          margin: 0;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .see-full-calendar {
    text-align: center;
    a.btn-outline-primary {
      padding-top: 10px;
    }

    button {
      &:focus {
        border:0;
        outline: $focus-outline !important;
      }
    }
  }
  .no-events-to-show {
    padding: 15px 0;
    .no-events-text {
      @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
      margin-top: 35px;
      color: $primaryBlack;
    }
  }

  @media (min-width: $screen-md-min) {
    .container {
      padding: 55px 90px 30px;
    }
    .modal {
      .modal-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .container {
      padding: 55px 90px;
    }
    .calendar-strip-heading {
      margin-bottom: 20px;
    }
    .filter-card {
      .calendar-widget {
        .datepicker {
          max-width: 280px;
          margin: 0;
        }
      }
      .calendar-widget-wrap {
        float: right;
      }
      .calendar-month-dropdown {
        max-width: 280px;
      }
    }
    .calender-strip-filter-column {
      padding-right: 65px;
    }
    .calendar-strip-list-column {
      border-left: 1px solid $secondarBorderBottomGray;
    }
    .no-events-to-show {
      padding: 0 35px;
    }
  }

  @media screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
    // https://gale43.atlassian.net/browse/BMOECON-441
    .calender-strip-filter-column {
      padding-right: 25px;
    }
    .calendar-strip-list-column {
      padding-left: 25px;
      .calendar-date-wrap {
        padding-left: 0;
      }
    }
  }

  .calender-strip-filter-column {
    .datepicker-days {
      .table-condensed {
        tbody {
          tr {
            td.day {
              @media (min-width: $screen-tab) {
                width: 40px !important;
                height: 33px !important;
                line-height: 1;
              }
              @media (min-width: $screen-xl-min) {
                width: 45px !important;
                height: 39px !important;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }

  .datepicker {
    .datepicker-days {
      .table-condensed {
        tbody {
          tr {
            td.day {
              @include belowTabletDateCircle();
            }
          }
        }
      }
    }
  }
}

.calender-view-main {
  .datepicker-days {
    .table-condensed {
      tbody {
        tr {
          td.day {
            @include belowTabletDateCircle();
          }
        }
      }
    }
  }
}
