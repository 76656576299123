/* How We Help Component */
.how-we-help-wrap {
  .how-we-help-container {
    position: relative;

    @media (max-width: $screen-sm-max) {
      padding: 0 20px;
    }

    @media (min-width: $screen-md-min) {
      padding: 55px 20px 0;
    }

    @media (min-width: $screen-lg-min) {
      padding: 35px 32px 20px;
    }

    .row {
      display: flex;
      justify-content: center;

      .help-card {
        display: flex;
        flex-direction: column;
        padding: 0;

        @media (max-width: $screen-sm-max) {
          padding: 0 10px;
        }

        @media screen and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
          display: inline-block;
        }

        @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
          margin-bottom: 0;
          max-width: 330px;
          padding: 0;
        }

        &:last-of-type {
          a {
            @media (max-width: $screen-sm-max) {
              border-bottom: 0;
              padding-bottom: 10px;
            }
          }
        }

        a {
          display: inline-block;
          text-align: center;
          flex: 1;

          &:hover,
          &:focus {
            text-decoration: underline;
            outline: $focus-outline;
            .help-arrow-link {
              cursor: pointer;
              background-image: url('assets/images/arrow-hover.png');
            }

            h1 {
              color: $primaryBlue;
            }
          }

          @media (max-width: $screen-sm-max) {
            border-bottom: solid .5px $secondaryBorderLineGray;
            padding: 23px 0 26px;
          }

          @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            margin-bottom: 4.5rem;
            width: 100%;
            padding: 0;
          }

          @media (min-width: $screen-lg-min) {
            border-right: solid .5px $secondaryBorderLineGray;
            padding: 0;
          }

          h1 {
            color: $secondaryGray;
            @include bmoFont(34px, $fontFamilyLight);
            line-height: 1.24;
            margin-bottom: 21px;
            min-height: 80px;
            max-height: 80px;
            overflow: hidden;
            padding: 0 45px;

            @media (max-width: $screen-sm-max) {
              max-width: 260px;
              margin: 0 auto;
              font-size: 25px;
              margin-bottom: 12px;
              max-height: 100px;
            }

            @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
              padding: 0 30px;
            }

            @media screen and (min-width: $screen-lg-min) and (max-width: 1300px) {
              padding: 0 29px 15px;
              min-height: 140px;
              max-height: 140px;
            }

            &:hover,
            &:focus {
              color: $primaryBlue;
            }
          }

          img {
            height: 62px;
            margin-bottom: 23px;

            @media (min-width: $screen-md-min) {
              height: 80px;
            }
          }

          p {
            color: $colorBlack;
            @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
            line-height: 1.19;
            min-height: 40px;
            max-height: 40px;
            overflow: hidden;
            padding: 0 40px;

            @media (max-width: $screen-sm-max) {
              width: 223px;
              line-height: 1.31;
              margin: 0 auto;
            }

            @media (max-width: $screen-md-max) {
              padding: 0;
            }

            @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
              width: 223px;
              margin: 0 auto;
            }

          }

          .help-arrow-link {
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-top: 15px;
            background-image: url('assets/images/arrow_link.png');
            background-repeat: no-repeat;
            background-size: cover;
          

            @media (min-width: $screen-lg-min) {
              margin: 15px 0;
            }
          }
        }

        &:last-child {
          a {
            @media (min-width: $screen-md-min) {
              border: 0;
            }
          }
        }

        // Second and third help card padding
        &:nth-child(2),
        &:nth-child(3) {
          h1 {
            @media screen and (min-width: $screen-md-min) {
              padding: 0 75px;
            }
          }
        }

        &:last-child {
          h1 {
            padding: 0 30px;
          }
        }
      }
    }
  }
}
