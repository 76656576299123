@mixin internationFlagBorder {
  &.international {
    .flag {
      border: 0;
    }
  }
}

@mixin calendarEventsListingStyles {
  h2 {
    text-align: left;
    color: $colorGray;
    margin: 0;
    line-height: 2;
  }
  .media {
    .flag {
      margin-right: 16px;
      border: 1px solid $calendarDisbaledGrey;
    }
    @include internationFlagBorder();
  }
  .date {
    display: block;
    vertical-align: top;
    @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
    color: $colorGray;
    display: block;
    min-width: 85px;
  }
  .text {
    display: inline-block;
    @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
    p {
      display: inline;
    }
  }
  .media {
    padding: 12px 0;
  }
  .events-data {
    margin-bottom: 15px;
    padding: 0;

    &:last-child {
      padding: 0;
    }
  }

  .calendar-date-wrap {
    border-bottom: 0.5px solid $secondarBorderBottomGray;
    padding: 15px 0
  }

  @media (max-width: $screen-sm-max) {
    .media-body {
      margin-top: 5px;
    }
    .media-body.parent-body {
      .media.events-data:first-child[data-alldayevent="True"] .media-body {
        margin-top: 0;
      }
    }
    .media-body {
      margin-top: 5px;
    }
    .download-ics {
      margin-left: 10px;
      width: 25px;
    }
  }

  @media (min-width: $screen-md-min) {
    h2 {
      font-size: $DesktopCopyRegular25;
    }
    .date {
      display: inline-block;
      padding-right: 15px;
      font-size: $DesktopCopyRegular16;
    }
    .cal-text,
    .text {
      font-size: $DesktopCopyRegular16;
    }
    .cal-text {
      width: 80%;
      color: $colorGray;
    }
    .flag {
      margin-right: 20px;
    }
    .media-body {
      padding: 7px 0;
    }
    .media-body .media .media-body {
      padding: 0;
      &:focus{
        outline: $focus-outline;
      }
    }
    .download-ics {
      width: 25px;
    }
    .events-data .media-body {
      display: flex;
    }
  }
  @media (min-width: $screen-lg-min) {
    padding-left: 30px;
    .events-data .media-body,
    .cbd-data-wrap .media-body {
      flex: inherit;
    }
    .download-ics {
      display: none;
      cursor: pointer;
      margin-left: 15px;
      width: 20px;
    }
    .cal-text {
      width: inherit;
      color: $colorGray;
    }
    .events-data {
      cursor: pointer;
      max-width: 80%;
      @media (min-width: $screen-xl-min) {
        max-width: 70%;
      }
      &:hover {
        p,
        .text {
          color: $primaryBlue;
        }
        .media-body+.download-ics {
          display: block;
        }
      }
    }
    .cbd-data-wrap .media {
      cursor: pointer;
      img.flag {
        cursor: default;
      }
      &:hover {
        color: $primaryBlue;
        .download-ics {
          display: block;
        }
      }
    }
    .calendar-date-wrap {
      padding-left: 35px;
      &:nth-child(1) {
        padding-top: 0;
        h2 {
          margin-top: 0;
        }
      }
    }
  }
}

.datepicker {
  .datepicker-cta{
    .closeCalendar{
      width: 100%;
      margin: 0;
      color: $primaryBlue;
      text-transform: uppercase;
      &:focus{
        outline: $focus-outline;
      }
    }
  }
}

@mixin calendarDatepickerStyles {
  .calendar-widget .datepicker {
    width: 100%;
    max-width: 320px;
    margin: auto;
    .datepicker-days {
      .prev,
      .next {
        display: none;
      }

      .table-condensed {
        thead {
         tr {
            &:nth-child(2) {
              height: auto;
            }
          }
        }
      }
      .datepicker-prv-nxt {
        .button-container {
          height: auto;
        }
      }
      .datepicker-prv-nxt{
        .button-container {
          .datepicker-switch {
            padding: 12.5px 10px;
          }
        }
      }

      .datepicker-switch {
        background-color: $primaryWhite;
        color: $primaryBlue;
        font-size: $DesktopCopyRegular20;
        text-align: left;
        width: 100%;
        position: relative;
        padding: 7px 10px;
        &:after {
          content: '';
          background-image: url('assets/images/blue-chevron.png');
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
          width: 18px;
          height: 10px;
          position: absolute;
          right: 13px;
          top: 19px;
        }
        &.chevron-up {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }
      .table-condensed {
        thead tr:nth-last-child(1) {
          background-color: $primaryWhite;
          border-top: 1px solid $primaryBlue;
          th {
            font-size: $DesktopCopyRegular14;
            width: 14.28%;
            text-transform: uppercase;
            color: $secondaryGray;
            position: relative;
            &:hover {
              background-color: transparent;
            }
            &:after {
              color: $secondaryGray;
              position: absolute;
              top: 15px;
              left: 50%;
              transform: translateX(-50%);
            }
            &:nth-child(1)::after {
              content: '';
            }
            &:nth-child(2)::after {
              content: '';
            }
            &:nth-child(3)::after {
              content: '';
            }
            &:nth-child(4)::after {
              content: '';
            }
            &:nth-child(5)::after {
              content: '';
            }
            &:nth-child(6)::after {
              content: '';
            }
            &:nth-child(7)::after {
              content: '';
            }
          }
        }
        tbody tr {
          td {
            background-color: $primaryWhite;
            @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
          }
          td.old {
            visibility: hidden;
          }
          td.new {
            display: none;
          }
          td.day {
            color: $primaryBlue;
            &:focus{
              outline: $focus-outline;
            }
          }
          td.disabled {
            color: $medGrayTertiary;
          }
          td.active {
            color: $primaryWhite;
            background-color: $primaryBlue;
            border-radius: 50%;
            padding-top: 2px !important;
            text-shadow: none;
          }
        }
      }
    }
  }

  .calendar-widget-wrap {
    position: relative;
    padding-bottom: 20px;
    .calendar-widget{
      &:focus{
        outline: $focus-outline;
      }
    }
  }
  .calendar-month-dropdown {
    display: none;
    max-width: 320px;
    background-color: $primaryWhite;
    box-shadow: $megamenu-modal;
    border-radius: 5px;
    position: absolute;
    padding: 4px;
    top: 60px;
    width: 98%;
    height: 252px;
    left: 50%;
    transform: translateX(-50%);
    overflow: scroll;
    li {
      @include bmoFont($DesktopCopyRegular20, $fontFamilyRegular);
      color: $primaryBlue;
      padding: 5px 15px;
      cursor: pointer;
      &:hover {
        background-color: $lightGrayTwo;
      }
    }
    &.econ-month {
      li.econ-disabled {
        color: $medGraySecondary;
        cursor: default;

        &:hover {
          background-color: $primaryWhite;
        }
      }
    }
    &.cbd-month {
      li.cbd-disabled {
        color: $medGraySecondary;
        cursor: default;

        &:hover {
          background-color: $primaryWhite;
        }
      }
    }
    &.show {
      display: block;
    }
  }
}

@mixin calendarModalStyles {
  .modal-dialog {
    margin: 0;
    max-width: 100vw;
    .modal-content {
      border: 0;
      border-radius: 0;
      padding: 16px;
      width: 100vw;
      height: 100vh;
    }
    .filter-card {
      overflow: scroll;
    }
    .close-button-container {
      text-align: right;
    }
    .close-button {
      width: 25px;
      height: 25px;
      background: white;
      background: url('assets/images/close_icon.png');
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0;
      margin: 15px;
      border: 0;
    }
  }
  @media (min-width: $screen-md-min) {
    .modal-dialog {
      margin: auto;
      max-width: max-content;
      .modal-content {
        max-width: 465px;
        height: auto;
      }
    }
  }
}

@mixin belowTabletDateCircle {
  @media (max-width: $screen-lg-min) {
    width: 40px !important;
    height: 45px !important;
    line-height: 1;
  }
}

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  outline: $focus-outline;
}
.datepicker table tr td.day.focused.active {
  outline: none;
}
.datepicker table tr td.day.focused.active:focus {
  outline: $focus-outline;
}