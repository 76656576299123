/* All custom variables will be placed here.*/
/************* Image and Font Public Path  ***********/

$publicImagePath : '../../public/assets/images' !default;

/************* Colors *************/

/* Primary colors */

$primaryBlue: #0079c1;
$primaryBlack: #212121;
$primaryWhite: #ffffff;
$medGray: #8c8c8c;
$medGraySecondary: #737373;
$medGrayTertiary: #717171;

$colorBlack: #000000;
$colorGray: #4a4a4a;
$colorRed: #ed2324;
$colorBlue: #004a7c;

$navLinkColor: #f3f4f6;

/* Secondary colors */

$negativeRed: #C81414;
$positiveGreen: #15a937;

$secondaryGray: #565656;
$secondarInputGray: #5d5d5d;
$secondaryAuthorInputGray: #b8b8b8;

$secondaryBorderGray: #d8d8d8;
$secondaryHeadingBlue: #1685c6;
$secondarBorderBlue: #73c3eb;
$secondayBorderLightGray: #dedede;
$secondarBorderBottomGray: #c7c7c7;
$secondaryBorderPublication: #e9e9e9;
$secondaryInputBorder: #bfbfbf;
$secondaryFilterBorder: #979797;
$secondaryBackgroundGray: #f3f3f3;
$disbaledDateInDatePicker: #f0f0f0;
$secondaryAccessibleBlue: #056fad;

// Here RGBA is used because IE doesn't support 8 digit hex color code i.e. #8c8c8c80
$secondaryBorderLineGray: rgba(140, 140, 140, .5);
$secondaryPublicationsBorderLineGray: rgba(140, 140, 140, .3);
$secondaryEconofactsBorderLineGray: rgba(199, 199, 199, .3);


$secondaryBoxShadow: #a6a6a6;
/* Light Color */

$lightGrayOne: #eeecec;
$lightGrayTwo: #f5f6f7;
$lightGrayThree: #f2f2f2;

$calendarDisbaledGrey: #b1b0b0;

/************* Font Family *************/

$fontfamilyDefault: 'Heebo', sans-serif;

/************* Font Size *************/

/* Desktop Body Font Size */

$DesktopCopyRegular50: 3.125rem; // 50px
$DesktopCopyRegular35: 2.1875rem; // 35px
$DesktopCopyRegular30: 1.875rem; // 30px
$DesktopCopyRegular26: 1.625rem; // 26px
$DesktopCopyRegular25: 1.5625rem; //25px
$DesktopCopyRegular22: 1.375rem; // 22px
$DesktopCopyRegular20: 1.25rem; // 20px
$DesktopCopyRegular18: 1.125rem; // 18px
$DesktopCopyRegular16: 1rem; // 16px Heebo-Light
$DesktopCopyRegular14: 0.89rem; // 14px
$DesktopCopyRegular12: 0.75rem; // 12px

/* Tablet Body Font Size */

$TabletCopyRegular16: 1rem; // 16px Heebo-Light
$TabletCopyRegular14: 0.89rem; // 14px Heebo-Bold

/* Mobile Body Font Size */

$MobileCopyRegular16: 1rem; // 16px Heebo-Light
$MobileCopyRegular14: 0.89rem; // 14px Heebo-Bold

$MobileLink20: 1.25em; // 20px
$MegaMenuLink20: 1.25em; // 20px

/************* Font Weight *************/

$fontFamilyLight: 300;
$fontFamilyRegular: 400;
$fontFamilyMedium: 500;
$fontFamilyBold: 700;

// -----------------------------------------
// Breakpoint Values
// -----------------------------------------

// Extra small screen / phone
$screen-xs             : 320px;
$screen-xs-min         : $screen-xs;

// Small screen
$screen-sm             : 576px;
$screen-sm-min         : $screen-sm;

// Tablet Screen

$screen-md             : 768px;
$screen-md-min         : $screen-md;

// Large/wide desktop
$screen-tab            : 1024px;

// initial desktop
$screen-initial-desktop-min: 1080px;

// Large Screen

$screen-lg             : 992px;
$screen-lg-min         : $screen-lg;


// Extra Large Screen

$screen-xl             : 1200px;
$screen-xl-min         : $screen-xl;

// Windows Large and Medium
$screen-windows-md     : 1280px;
$screen-windows-lg     : 1440px;
$screen-windows-min-lg : $screen-windows-lg;
$screen-windows-max-lg : $screen-windows-lg+1;

$screen-xs-max     : ($screen-sm-min - 1);
$screen-sm-max     : ($screen-md-min - 1);
$screen-md-max     : ($screen-lg-min - 1);

// ---------------------------------------------
// Z-Index Stack
// ---------------------------------------------

$zindex-basic:             1 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-overlay:           1040 !default;
$zindex-dropdown:          1045 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;

// ---------------------------------------------
// Bookmark Notification Dropdown
// ---------------------------------------------

$secondary-boxshadow-color: rgba(166, 166, 166, 0.5);

$bookmark-dropdown: 0 5px 13px 0 $secondary-boxshadow-color;
$megamenu-modal: 0 2px 4px 0 rgba(197, 197, 197, 0.5);
$sticky-nav-bar: 0 5px 13px 0 rgba(86, 86, 86, 0.85);
$calendar-weekdays-strip: 0 2px 25px 0 rgba(193, 193, 193, 0.5);
$calendar-filter-block: 0 2px 7px 0 rgba(129, 129, 129, 0.5);
$economist-info-window: 1px 6px 54px 5px $secondaryBoxShadow;
$preview-window-block: 1px 6px 54px 5px $secondaryBoxShadow;
$mobile-filter-options: 0 5px 13px 0 rgba(0, 0, 0, 0.13);
$auto-suggest-modal: 0 8px 7px 0 rgba(129, 129, 129, 0.5);

// ---------------------------------------------
// Bookmark Notification Dropdown
// ---------------------------------------------

$background-color-multiple-events: rgba(22, 133, 198, 0.1);

// ---------------------------------------------
// Background colors for Calendar Tablet
// ---------------------------------------------

$current-date-background: rgba(22, 133, 198, 0.15);
$selected-date-background: rgba(208, 2, 27, 0.1);

//-----------------------------------------------
// Focus Outline for accessibility
//------------------------------------------------

$focus-outline: 2px solid #59c8fb;
