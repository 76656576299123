/* Publication calendar widget styles override */

@mixin iconSizing {
  background-repeat: no-repeat;
  background-size: 12px;
}

.datepicker.datepicker-dropdown {
   
  .datepicker-days, .datepicker-months, .datepicker-years, .datepicker-decades, .datepicker-centuries{
    .table-condensed {
      thead {
       tr {
        &:nth-child(2) {
          th.datepicker-switch{
            width: 145px;
            &:focus{
              outline: $focus-outline;
            }
          }
        }
       }
      }
    }
  }
}

.datepicker {
  &.dropdown-menu {
    @media (max-width: $screen-md-min - 1) {
      width: 100%;
      left: 0 !important;
      top: 45px !important;
    }
    width: 250px;
    left: -17px !important;
    padding: 0;
    top: 45px !important;
    background: $primaryWhite;
    border: 1px solid $colorGray;
    box-shadow: none;
    border-radius: 0;
  }
  &.datepicker-dropdown.datepicker-orient-bottom, &.datepicker-dropdown.datepicker-orient-top {
    &::before, &::after {
      border: 0 !important;
    }
  }

  /* Next and Previous dates */
  .prev, .next {
    width: 100px;
  }

  .prev.disabled, .next.disabled {
    visibility: inherit;
    pointer-events: none;
    background: $primaryBlue;
  }
  .datepicker-prv-nxt{
    .button-container {
      .datepicker-switch {
        width: 66%;
        text-align: center;
      }
      display: flex;
      height: 55px;
      justify-content: center;
      align-items: center;
      a,button  {
        @include bmoFont($DesktopCopyRegular18, $fontFamilyRegular);
        border: none;
        background: none;
        &.datepicker-switch:hover {
          background: transparent;
        }
        color: #ffffff;
      }
    }
    background: $primaryBlue;
    height: 55px;
    a,button {
      @include bmoFont($DesktopCopyRegular18, $fontFamilyRegular);
      border: none;
      background: none;
      &.prev,
      &.next {
        // background-image: url('static/assets/images/calendar_widget_left.png');
        // background-repeat: no-repeat;
        // background-size: 12px;
        // background-position: 20px 18px;
        color: transparent;
        position: relative;
        padding: 4px 24px;
        width: 50px;
        background-position: 20px 3px !important;
        width: 17%;
      }
    }
    @include calendarWidgetPrevNextIcon();
  }
  .datepicker-days {
    .table-condensed {
      width: 100%;

      thead {
       tr {
          th {
            border-radius: 0;
            color: $primaryWhite;
            &:hover {
              background: $primaryBlue;
            }
          }
          &:nth-child(2) {
            background: $primaryBlue;
            height: 55px;
            display: none;
            th {
              @include bmoFont($DesktopCopyRegular18, $fontFamilyRegular);
            }
          }
          &:nth-last-child(1) {
            background: $colorBlue;
            height: 42px;
            th {
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              &:hover {
                background: $colorBlue;
              }
            }
          }
       }
      }

      thead tr:nth-last-child(2) {
        @include calendarWidgetPrevNextIcon();
      }

      thead tr:nth-last-child(1) {
        th {
          font-size: $DesktopCopyRegular14;
          width: 14.28%;
          text-transform: uppercase;
          position: relative;
          &:hover {
            background-color: transparent;
          }
          &:after {
            color: $primaryWhite;
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:nth-child(1)::after {
            content: '';
          }
          &:nth-child(2)::after {
            content: '';
          }
          &:nth-child(3)::after {
            content: '';
          }
          &:nth-child(4)::after {
            content: '';
          }
          &:nth-child(5)::after {
            content: '';
          }
          &:nth-child(6)::after {
            content: '';
          }
          &:nth-child(7)::after {
            content: '';
          }
        }
      }

      tbody {
        tr {
          td.day {
            width: 70px;
            height: 39px;
            padding: 8px 0;
            @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
            color: $colorGray;
            &:hover {
              border-radius: 0;
              background: $secondaryBorderPublication;
            }
          }
          td.new.day {
            color: $medGraySecondary;
          }
          td.today.day {
            background: transparent;
            border-radius: 50%;
            padding: 0px;
            color: $colorGray;
          }
          td.active.day {
            background: $negativeRed;
            border-radius: 50%;
            padding: 0px;
            color: $primaryWhite;
          }
          td.day.disabled {
            background-color: $disbaledDateInDatePicker;
            border-radius: 0;
          }
        }
      }
    }
  }

  .datepicker-months {
    .table-condensed {
      width: 100%;
      thead {
        tr {
           th {
            border-radius: 0;
            color: $primaryWhite;
            &:hover {
              background: $primaryBlue;
            }
          }
          &:nth-last-child(1) {
            background: $primaryBlue;
            height: 55px;
            display: none;
            th {
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              &:hover {
                background: $primaryBlue;
              }
            }
          }
        }
      }

      thead tr:nth-last-child(1) {
        @include calendarWidgetPrevNextIcon();
        th.next {
          background-position: 65px 18px;
        }
      }

      tbody {
        tr {
          td {
            padding: 0;
            span {
              margin: 0;
              width: 33.2%;
              height: 52px;
              border-radius: 0;
              text-shadow: none;
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              color: $colorGray;
              &.active {
                background: $secondarBorderBottomGray;
              }
            }
          }
        }
      }
    }
  }
}

.datepicker-years, .datepicker-decades, .datepicker-centuries {
  .table-condensed {
    width: 100%;
    thead {
      tr {
         th {
          border-radius: 0;
          color: $primaryWhite;
          &:hover {
            background: $primaryBlue;
          }
        }
        &:nth-last-child(1) {
          background: $primaryBlue;
          display: none;
          height: 55px;
          th {
            @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
            &:hover {
              background: $primaryBlue;
            }
          }
        }
      }
    }

    thead tr:nth-last-child(1) {
      @include calendarWidgetPrevNextIcon();
      th.next {
        background-position: 65px 18px;
      }
    }

    tbody {
      tr {
        td {
          padding: 0;
          span {
            margin: 0;
            width: 33.2%;
            height: 52px;
            border-radius: 0;
            text-shadow: none;
            color: $colorGray;
            @include bmoFont($DesktopCopyRegular20, $fontFamilyRegular);
            &.active {
              background: $secondarBorderBottomGray;
              &:hover {
                color: $colorGray;
                text-shadow: none;
                background: $secondarBorderBottomGray;
              }
            }
          }
        }
      }
    }
  }
}

/* Lable as container */
.to-date-container, .to-date-container-mobile {
  .datepicker {
    &.dropdown-menu {
      top: 125px !important;
      left: -1px !important;
      @media (max-width: $screen-sm-max) {
        top: 45px !important;
      }
    }
  }
}
.to-date-container-mobile {
  .datepicker {
    &.dropdown-menu {
      left: 0px !important;
    }
  }
}
