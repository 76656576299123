/* All custom Mixins will be plased here.*/

@mixin bmoFont($size, $weight) {
  font-family: $fontfamilyDefault;
  font-size: $size;
  font-weight: $weight;
}

@mixin horizontalLine($width) {
  border: solid .5px $secondaryBorderLineGray;
  margin: 1rem auto;
  width: $width;
}

/* Modal Close btn */
@mixin modalCloseBtn {
  width: 26px;
  height: 26px;
  background: url('assets/images/close_icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  outline: none;
  margin-right: 15px;
  opacity: 1;
  margin: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: $zindex-sticky;
}

@mixin dropDownUpChevron {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
}

@mixin dropDownUpChevronBefore {
  width: 16px;
  height: 14px;
  content: '';
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin phoneEmailLinkIcon {
  a {
    &:hover {
      text-decoration: none;
    }

    .btn {
      width: 21px;
      height: 21px;
      margin-right: 20px;
      background-repeat: no-repeat;

      &.mail-icon {
        background-image: url('assets/images/mail-icon.png');
        background-size: contain;
        &:hover{
          background-image: url('assets/images/envelope-hover.png');
        }
      }

      &.phone-icon {
        background-image: url('assets/images/phone-icon.png');
        background-size: contain;
        &:hover{
          background-image: url('assets/images/phone-hover.png');
        }
      }
    }

    &:last-child {
      .btn {
        margin: 0;
      }
    }
  }
}

@mixin modalCloseMobile {
  width: 26px;
  height: 26px;
  background: url('assets/images/close_icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  outline: none;
  margin-right: 15px;
  opacity: 1;
  margin: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: $zindex-sticky;
  @media (max-width: $screen-md-min) {
    width: 20px;
    height: 20px;
    top: 15px;
    right: 20px;
  }
}

/* Page loader before after mixin */
@mixin pageLoaderSize {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: 0 0 0 -33px;
  border-radius: 50%;
}

@mixin pageLoader {
  padding-top: 60px;
  text-align: center;
  color: $primaryBlue;
  @include bmoFont($DesktopCopyRegular20, $fontFamilyBold);
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translateX(-50%);

  &::before, &::after {
    @include pageLoaderSize();
    border: .2em solid $secondaryBorderPublication;
  }
  &::after {
    @include pageLoaderSize();
    -webkit-animation: loader .6s linear;
    animation: loader .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-color: $primaryBlue transparent transparent;
    border-style: solid;
    border-width: .2em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }
}

@mixin authorFilterList {
  ul {
    padding: 0 15px;
    li {
      width: 100%;
      padding: 10px 15px;
      border-radius: 5px;
      @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
      background: $secondaryBorderPublication;
      color: $colorGray;
      position: relative;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .remove-author, .remove-author-mobile {
        position: absolute;
        right: 15px;
        width: 15px;
        height: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        border: 0;
        cursor: pointer;
        padding: 0px;
        background: $secondaryBorderPublication url('assets/images/close_icon.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

@mixin publicationFilters {
  @media (max-width: $screen-sm-max) {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 25px;
  }

  padding: 0;
  width: 250px;
  position: relative;

  .sidebar__inner {
    box-shadow: $calendar-filter-block;
    height: fit-content;
    .card {
      border: 0;
      border-radius: 0;

      @media (max-width: $screen-sm-max) {
        border-bottom: 1px solid $primaryWhite;
      }

      &.reset-filters {
        .card-header {
          border: 1px solid $secondayBorderLightGray;
        }
      }

      &.author-card {
        border-bottom: 1px solid $primaryWhite;
      }

      &:first-child {
        .card-header {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      &:last-child {
        .card-header {
          border: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          // border-top: 1px solid $secondayBorderLightGray;
        }
      }

      &:nth-child(1) {
        .card-header {
          .btn {
            @media (min-width: $screen-md-min) {
              border-radius: 0;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }
        }
      }

      &:nth-child(2) {
        .card-header {
          .btn {
            border-radius: 0;
          }
        }
      }

      .publication-filters-list{
        input[type=checkbox]:focus{
          + span{
            outline: $focus-outline;
          }
        }
      }

      .card-header {
        padding: 0;
        border-radius: 0;
        border-bottom: 0;

        @media (max-width: $screen-sm-max) {
          background: $colorBlue;
        }

        .btn {
          width: 100%;
          text-align: left;
          padding: 10px 0 7px 15px;
          @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
          color: $primaryWhite;
          text-transform: capitalize;
          background: $primaryBlue;
          border-radius: 0;

          &:hover,
          &:focus {
            text-decoration: none;
          }

          .drop-down-icon-acc {
            position: relative;
            float: right;
            content: "";
            display: block;
            height: 15px;
            width: 15px;
            background-size: cover;
            background-position: 50%;

            &:before {
              border-style: solid;
              border-width: 1px 1px 0 0;
              content: "";
              display: inline-block;
              height: 10px;
              position: relative;
              top: 8px;
              right: 22px;
              transform: rotate(-45deg);
              vertical-align: top;
              width: 10px;
              float: right;
              color: $primaryWhite;
            }
          }

          &.collapsed {
            .drop-down-icon-acc {
              &:before {
                transform: rotate(135deg);
                top: 3px;
              }
            }
          }
        }

        .btn[aria-expanded="true"] {
          background: $primaryBlue;

          @media (max-width: $screen-sm-max) {
            background: $primaryBlue;
          }
        }
      }

      .card-body {
        padding: 0;
        border-top: 0;
        border-bottom: 0;
        position: relative;

        @media (max-width: $screen-sm-max) {
          border: 0;
        }

        ul {
          border-bottom: 0;

          li {
            border: 0;
            margin: 0;
            padding: 13px 10px 13px 15px;
            border-radius: 0;
            border-bottom: 0;
            cursor: pointer;

            &.bold-text {
              font-weight: $fontFamilyMedium;
            }

            &:last-child {
              border-bottom: 0;
            }

            p {
              display: inline-block;
              @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
              color: $colorGray;
            }

            /* Filter options check */
            .bmo_chevron {
              display: none;

              &:before {
                @include filterOptionCheck();
              }

              &.tick {
                &:before {
                  @include filterCheckBlue();
                }
              }
            }

            &.selected-filter {
              p {
                font-weight: $fontFamilyMedium;
              }

              .bmo_chevron {
                display: inline;
              }
            }

            /* Custom checkbox */
            label {
              margin: 0;
              width: 100%;
              cursor: pointer;
              position: relative;
              @media (max-width: $screen-sm-max) {
                width: 100%;
                position: relative;
              }
              span {
                @media (max-width: $screen-sm-max) {
                  padding: 0 15px;
                }
              }
            }
            @include customCheckbox();
            input[type="checkbox"]:checked + span, input[type="checkbox"]:not(:checked) + span {
              padding-left: 35px;
            }
          }
        }

        .author-selected-list-on-filter {
          @include authorFilterList();
          ul li {
            background: transparent;
            padding: 5px 15px;
            cursor: default;
            .remove-author {
              display: none;
            }
          }
          ul li:last-child {
            margin-bottom: 15px;
          }
        }

        /* Author search */
        .author-search {
          padding: 20px 15px;
          input {
            width: 221px;
            @include searchInputIcon();
            &:hover, &:focus{
              outline: $focus-outline !important;
            }
            &::placeholder {
              color: $secondaryAuthorInputGray;
            }
            @media (max-width: $screen-sm-max) {
              width: 100%;
            }
          }
        }

        .search-highlight-text {
          color: $primaryBlue;
        }
      }
    }

    .publication-filter-js {
      border-top: 0;
      border-bottom: 0;
      .card  {
        .card-header {
          border-bottom: 1px solid $primaryWhite;
          .btn {
            background: $primaryBlue;
            color: $primaryWhite;
            @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
            text-transform: capitalize;
            border-radius: 0;
          }
        }
      }
    }

    /* Author card */
    .author-desktop {
      .card-body {
        border-bottom: 0;
        position: relative;
      }

    /* Author search modal */
      .author-search-modal, .author-search-modal-mobile {
        width: 100%;
        position: absolute;
        background: $primaryWhite;
        box-shadow: $sticky-nav-bar;
        border-radius: 5px;
        top: 50%;
        left: 100%;
        transform: translateY(-57%);
        padding: 15px 0 0;
        z-index: $zindex-sticky;

        @media (max-width: $screen-sm-max) {
          top: 0;
          position: absolute;
        }

        .author-search-mobile-close {
          @include modalCloseBtn();
          border: 0;
        }

        .author-auto-suggest-block, .author-auto-suggest-block-mobile {
          ul {
            padding: 0 20px 0 60px;
            overflow-y: scroll;
            border: 0;
            li {
              padding: 10px 0;
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              color: $secondaryGray;
              margin: 4px 0;
            }
          }
          p {
            padding: 10px 0;
            @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
            color: $secondaryGray;
            text-align: center;
          }
        }

        .author-auto-suggest-block-mobile {
          @media (max-width: $screen-sm-max) {
            overflow-y: scroll;
          }
        }

        .author-selected-list, .author-selected-list-mobile {
          @include authorFilterList();
        }

        .author-search-input, .author-search-input-mobile {
          margin: 10px 15px 20px;
          input {
            width: 100%;
            @include searchInputIcon();
          }
        }

        .submit-author {
          margin: 20px 40px 40px;

          &.disable-submit {
            border: 1px solid $medGray;
            color: $medGraySecondary;
          }
        }
      }

      .author-search-modal-mobile {
        padding-top: 50px;

        @media (max-width: $screen-sm-max) {
          overflow-y: scroll;
        }
        .submit-author {
          margin: 0 auto;
          display: block;
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
    }
  }

  /* Date Range */
  ul.date-range {
    padding: 20px 15px 0;
    li {
      padding: 0 !important;
      > span {
        @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
        color: $colorGray;
        line-height: 40px;
      }
      input {
        width: 173px;
        height: 40px;
        border: 1px solid $secondaryInputBorder;
        border-radius: 2px;
        float: right;
        margin-bottom: 20px !important;
        background: url(assets/images/calendar_widget.png) no-repeat 95% 9px;
        background-size: 20px;
        padding-left: 16px;
        color: $colorGray;
        cursor: pointer;
        &:hover, &:focus{
          outline: $focus-outline;
        }

        @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          &::-ms-clear {
            width : 0;
            height: 0;
            display: none;
          }
        }
      }

      > label {
        span {
          line-height: 2;
          @media (max-width: $screen-sm-max) {
            padding: 0 15px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

/* Border Top Left Radius */
@mixin borderRadiusTopLeftRight {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@mixin borderRadiusBottomLeftRight {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@mixin noBorderRadiusTopLeftRight {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@mixin noBorderRadiusBottomLeftRight {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@mixin calendarWidgetPrevNextIcon {
  th,button,a {
    &:nth-child(1), &:nth-last-child(1) {
      color: transparent;
      position: relative;
    }
    &.prev {
      background-image: url('assets/images/calendar_widget_left.png');
      @include iconSizing();
      background-position: 20px 18px;
      &:focus{
        outline: $focus-outline;
      }
    }
    &.next {
      background-image: url('assets/images/calendar_widget_right.png');
      @include iconSizing();
      background-position: 5px 18px;
      &:focus{
        outline: $focus-outline;
      }
    }
    &.datepicker-switch {
      &:focus{
        outline: $focus-outline;
      }
    }
  }
}
