/* Component specific mixins */
@mixin linkArrowRight {
  position: relative;
  display: inline-block;

  &:before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: inline-block;
    height: 10px;
    position: relative;
    top: 0;
    right: 0;
    left: 10px;
    transform: rotate(45deg);
    vertical-align: top;
    width: 10px;
    float: right;
    color: $primaryBlue;
  }
}

@mixin searchInputIcon {
  height: 40px;
  border: 1px solid $secondaryInputBorder;
  border-radius: 5px;
  background: url(assets/images/quick_search.png) no-repeat 7px 9px;
  background-size: 20px;
  padding-left: 40px;
}


/* Econofacts publications table */
.publications-table-template {
  .publications-icons-block {
    @media (max-width: $screen-sm-max) {
      padding: 13px 15px 23px;
    }

    .publications-heading {
      .quick-links {
        display: none;
        @media (min-width: $screen-md-min) {
          display: inline-block;
          vertical-align: top;
          margin-left: 50px;
        }
        &:active {
          color: $primaryBlue;
          background: transparent;
        }
        &:focus {
          border: 0;
          outline: $focus-outline;
        }
      }

      .quick-links-mobile {
        display: block;
        @media (min-width: $screen-md-min) {
          display: none;
        }
      }

      .publications-icon, .publications-heading-title {
        display: inline-block;
        vertical-align: top;
        @media (max-width: $screen-sm-max) {
          vertical-align: middle;
        }
      }

      .publications-heading-title {
        h1 {
          @media (max-width: $screen-sm-max) {
            font-size: $DesktopCopyRegular25;
            margin-left: 10px;
          }
        }
        p {
          display: block;
          margin: 0 10px;
          @include bmoFont($DesktopCopyRegular20, $fontFamilyRegular);
          color: $primaryBlue;
          span {
            @include linkArrowRight();
            &::before {
              left: 0;
            }
          }
          @media (min-width: $screen-md-min) {
            display: none;
          }
        }
      }
    }

    /* Quick Links Modal */
    .quick-links {
      .modal-dialog {
        max-width: 1022px;
        top: 150px;

        @media (max-width: $screen-md-min - 1) {
          top: 0;
          margin: 0;
          height: 100vh;
          max-width: 100%;
          border-radius: 0;
        }
      }

      .modal-content {
        border: 0;
        border-radius: 0;
        box-shadow: $megamenu-modal;
        overflow-y: auto;
        height: 633px;
        max-height: 100%;

        @media (max-width: $screen-md-min - 1) {
          min-height: 100%;
          border-radius: 0;
          border: 0;
        }

        @media (max-width: $screen-sm-max) {
          padding: 66px 0 30px;
        }

        @media (min-width: $screen-md-min) {
          padding: 50px 70px 60px;
          width: 90%;
          margin: 0 auto;
        }

        -ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-overflow-x: hidden;

        .modal-header {
          @media (max-width: $screen-sm-max) {
            display: inline-block;
            padding: 0 20px;
            margin: 0;
          }

          padding: 0;
          width: 60%;
          margin: 0 auto;
          border-bottom: 0;
          margin-bottom: 25px;

          @media (min-width: $screen-md-min) {
            width: 68%;
          }

          h1 {
            @media (max-width: $screen-sm-max) {
              font-size: $DesktopCopyRegular30;
            }
            color: $colorGray;
            font-weight: $fontFamilyLight;
            margin: 0;
            text-align: left;
            width: 100%;

            @media (max-width: $screen-tab - 1) {
              text-align: center;
            }
          }
          button.close {
            @include modalCloseBtn();

            @media (max-width: $screen-sm-max) {
              right: 15px;
              top: 15px;
              background-size: 80%;
            }

            @media (min-width: $screen-md-min) {
              right: 34px;
              top: 28px;
            }

            @media (min-width: $screen-tab) {
              top: 64px;
              right: 62px;
              &:focus {
                outline: $focus-outline;
              }
            }
          }
        }

        .btn {
          opacity: 1;
          width: 146px;
          margin: 10px auto;
          float: none;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          &:focus {
            border: 0;
            outline: $focus-outline;
          }
          @media (max-width: $screen-sm-max) {
            line-height: 40px;
            margin-top: 30px;
          }
          @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            left: 0;
            transform: translateX(0);
          }
        }

        .modal-body {
          padding: 0;
          margin: 0 auto;
          @media (max-width: $screen-sm-max) {
            flex: inherit;
            width: 100%;
          }

          @media (min-width: $screen-lg-min) {
            width: 73%;
          }

          .link-title-wrap {
            display: inline-block;
            width: 240px;
            max-width: 100%;
            vertical-align: top;
            margin-right: 30px;
            @media (max-width: $screen-sm-max) {
              width: 100%;
              margin: 0;
              margin-top: 20px;
            }
          }

          .footer-sub-heading {
            @include bmoFont($DesktopCopyRegular20, $fontFamilyRegular);
            color: $secondaryGray;
            padding: 9px 15px;
            margin: 0;
            width: 240px;
            border-bottom: 1px solid $primaryBlue;
            line-height: inherit;
            @media (max-width: $screen-sm-max) {
              padding: 10px 20px;
              width: 100%;
            }
          }

          ul {
            @media (max-width: $screen-sm-max) {
              width: 100%;
              margin: 0;
              display: block !important;
            }
            &:last-child {
              margin: 0;
            }

            li {
              border-bottom: solid 1px $secondaryPublicationsBorderLineGray;
              a {
                @media (max-width: $screen-sm-max) {
                  padding: 14px 25px 7px;
                }
                padding: 15px;
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                color: $primaryBlue;
                display: block;
                &:hover, &:focus{
                  text-decoration: underline!important;
                }
              }
            }

            &:last-child {
              @media (max-width: $screen-sm-max) {
                margin-top: 0;
              }
            }
          }
        }
        .modal-footer {
          display: block;
          border: 0;
          @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            text-align: center;
          }
        }
      }
    }

    /* Publication modal mobile */
    .publication-links-mobile-modal {
      @media (max-width: $screen-sm-max) {
        overflow-y: scroll;
      }

      .modal-dialog {
        @media (max-width: $screen-sm-max) {
          top: 0;
          margin: 0;
          height: 100vh;
          max-width: 100%;
          border-radius: 0;
        }

        .modal-content {
          @media (max-width: $screen-sm-max) {
            border: 0;
            border-radius: 0;
          }
        }

        .modal-header {
          border: 0;
          .mobile-close {
            @include modalCloseBtn();
            border: 0;
          }
        }

        .modal-body {
          padding: 0;
          margin-top: 20px;
          .publication-filter-accordion-mobile {
            padding: 0;

            #sidebar .card:first-child .card-header, #sidebar .card:first-child .card-header .btn {
              border-radius: 0;
            }

            #sidebar {
              .card {
                .card-body {
                  ul.date-range li input {
                    width: 80%;
                  }
                  .author-search input {
                    width: 100%;
                  }
                }
              }
            }

          }
        }
      }
    }
  }

  .econofacts-publications-table {
    #sidebar {
      @media (max-width: $screen-sm-max) {
        display: none;
      }
      @include publicationFilters();
    }

    .publication-filter-accordion-mobile {
      @include publicationFilters();
      ul.date-range {
        padding: 20px 0 0;
      }
      ul.date-range li input {
        @media (max-width: $screen-sm-max) {
          width: 70%;
          display: inline-block;
          margin-right: 9%;
        }
      }
    }

    > .publication-filter-accordion {
      @media (max-width: $screen-sm-max) {
        display: none;
      }
      /* Accordion sticky */
      #sidebar {
        @media (min-width: $screen-md-min) {
          position: absolute;
          width: 250px;
          float: left;
        }
      }
    }

    .publication-filter-accordion-mobile {
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
  }

  .load-more-publications {
    @include loadMorePublicationBtn();

    &:focus {
      border: 0;
      outline: $focus-outline;
    }
  }
}

#economist-details-container, #econofacts-list-container {
  .page-loader {
    top: 15%;
    @media (max-width: $screen-tab - 1) {
      top: 5%;
      margin-top: 150px;
    }

    @media (min-width: $screen-tab) {
      margin-top: 100px;
    }
  }
}

#content {
  .page-loader {
    top: 10%;
    @media (max-width: $screen-tab - 1) {
      top: 5%;
      margin-top: 150px;
    }

    @media (min-width: $screen-tab) {
      margin-top: 100px;
    }
  }
}
