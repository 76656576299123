/************* body padding fix in IE *************/

body {
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 0 !important;
  }
  &::after{
    display:none;
    content: url('assets/images/mail-icon.png') url('assets/images/envelope-hover.png') url('assets/images/phone-icon.png') url('assets/images/phone-hover.png') url('assets/images/arrow_link.png') url('assets/images/arrow-hover.png') url('assets/images/preview.png') url('assets/images/preview-fill.png') url('assets/images/eye-hover.png') url('assets/images/bookmar_path@2x.png') url('assets/images/bookmark-hover.png');
  }
}

/************* max screen size *************/

.screen-max-width {
  max-width: 1440px;
  margin: 0 auto;
}

/************* Custom Font Size *************/

h1 {
  /* Mobile First */

  font-family: $fontfamilyDefault;
  font-size: 30px;
  font-weight: $fontFamilyLight;
  line-height: 1.5;
  text-align: center;

  @media (min-width: $screen-md-min) {
    font-size: 50px;
  }
}

h2 {
  /* Mobile First */

  font-family: $fontfamilyDefault;
  font-size: 25px;
  font-weight: $fontFamilyLight;
  line-height: 1.2;
  text-align: center;

  @media (min-width: $screen-md-min) {
    font-size: 40px;
  }
}

h3 {
  /* Mobile First */

  font-family: $fontfamilyDefault;
  font-size: 20px;
  font-weight: $fontFamilyLight;
  line-height: 1.2;
  text-align: center;

  @media (min-width: $screen-md-min) {
    font-size: 35px;
  }
}

h4 {
  /* Mobile First */

  font-family: $fontfamilyDefault;
  font-size: 16px;
  font-weight: $fontFamilyLight;
  color: $primaryBlue;
  line-height: 1.1;
  text-align: center;

  @media (min-width: $screen-md-min) {
    font-size: 25px;
  }
}

h5 {
  /* Mobile First */

  font-family: $fontfamilyDefault;
  font-size: 25px;
  font-weight: $fontFamilyLight;
  line-height: 2.1;
  text-align: center;
}


/************* No padding margin <ul> *************/

ul {
  margin: 0;
  padding: 0;
}

/************* List style none <li> items  *************/

ul li {
  list-style: none;
}

/************* No margin <p>  *************/

p {
  margin: 0;
}

/************* No text-decoration <a>  *************/
a:hover {
  text-decoration: none;
}

/************* Overriding opacity for modal backdrop  *************/

.modal-backdrop.show {
  opacity: 0.85;
}

/******* Visibility hidden *************/
.visibility-hidden {
  visibility: hidden;
}

/* Accordion sticky */
.sticky-accordion {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-action-filter {
  position: fixed;
  top: 110px;
  z-index: 1;
  width: 250px;
}

/* CSS Page Loader */
.page-loader-mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.9;
  background-color: $primaryWhite;
  z-index: $zindex-overlay;
  text-align: center;
  visibility: visible;

  &.page-loader-hide {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
  }
}
.page-loader {
  @include pageLoader();
  &.publication-loader {
    @include pageLoader();
    @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
  }
}

@-webkit-keyframes loader {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes loader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Econofacts and Publication sticky */
.sticky-action {
  position: fixed;
  top: 110px;
  width: 118px;
}

.bottom-sticky-action {
  position: absolute;
  bottom: 80px;
  width: 250px;
}

.modal-open {	
  overflow: hidden;	
  @media (max-width: $screen-md-min) {	
    position:fixed;	
    width: 100%;	
  }	
}

// landmark css override for accessability purpose with custom class.
.main-landmark {
  width: 0;
  margin: 0;
  padding: 0;
  a.skip-navigation {
    position:absolute;
    left:-1000px;
    top:-1000px;
    width:1px;
    height:1px;
    text-align: left;
    overflow:hidden;
  }
  a.skip-navigation:focus, a.skip-navigation:active, a.skip-navigation:hover {
    position:absolute;
    color: $primaryBlue;
    left:0;
    top:0;
    width:auto;
    height:auto;
    overflow:visible;
    background-color: $primaryWhite;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.6);
    padding: 15px 23px 14px;
    outline: $focus-outline;
    text-decoration: none;
  }
}

:focus{
  outline: $focus-outline;
}