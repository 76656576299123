.economist-profile-main {
  .container {
    padding: 0;
  }

  .economist-profile-header {
    padding: 27px 15px 31px;

    h1 {
      color: $secondaryGray;
    }

    h2{
      display: inline-block;
      color: $primaryBlue;
      font-weight: $fontFamilyRegular;

      &:hover, &:focus{
        text-decoration: underline!important;
      }
    }

    img {
      display: inline-block;
      transform: rotate(90deg);
      margin: 0 5px 5px 0;
    }

    .back-to-economists {
      display: inline-block;
      cursor: pointer;
      &:focus,
      &:hover {
        h3 {
          text-decoration: underline;
        }
      }
    }
  }

  .economist-profile-container {
    padding: 0 25px 25px;

    .profile-photo {
      margin-bottom: 15px;
    }

    .economist-designation {
      width: 210px;
      font-weight: $fontFamilyRegular;
      line-height: 1.45;
      color: $colorGray;
    }

    .economist-profile-divider {
      @include horizontalLine(70%);
      margin-left: 0;
    }

    .economist-contact-block {
      a {
        color: $primaryBlue;
        display: block;
        white-space: nowrap;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        img {
          margin-right: 16px;
        }
      }

      a:first-child {
        padding-bottom: 16px;
      }
    }
  }

  .economist-discription {
    padding: 5px 25px 16px;
    color: $colorGray;
    @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);

    span.show-more-span a {
      color: $primaryBlue;
    }

    .economist-discription-content * {
      // here the important is used to overide the rich text inline font.
      font-family: $fontfamilyDefault !important;
    }
    .economist-discription-content > *:last-child {
      display: inline;
    }
  }

  .economist-profile-end-divider {
    @include horizontalLine(calc(100% - (16px * 2)));
  }

  @media (min-width: $screen-md-min) {
    .economist-profile-header {
      padding: 17px 30px 13px;

      h1 {
        font-size: $DesktopCopyRegular50;
        font-weight: $fontFamilyLight
      }

      h2 {
        font-size: $DesktopCopyRegular20;
        font-weight: $fontFamilyRegular;
      }
    }

    .economist-profile-container {
      display: flex;
      min-width: 555px;

      .economist-profile-content {
        margin-left: 36px;

        .profile-photo {
          height: 145px;
          width: 145px;
        }

        .economist-designation {
          font-size: $DesktopCopyRegular20;
        }

        .economist-profile-divider {
          width: 120%;
        }

        .economist-contact-block {
          a img {
            margin-right: 14px;
          }
        }
      }
    }

    .economist-discription,
    .economist-profile-container {
      padding: 15px 30px;
    }

    .economist-profile-end-divider {
      @include horizontalLine(calc(100% - (30px * 2)));
    }
  }

  @media (min-width: $screen-lg-min) {
    .economist-profile-header {
      padding: 45px 30px 20px;
    }

    .economist-profile-container,
    .economist-discription {
      padding: 20px 30px;
    }

    .economist-profile-container {
      padding-right: 60px;

      .economist-profile-divider {
        width: 100%;
      }
    }

    .economist-profile-discription {
      display: flex;
    }
  }
}
// Recent Publications table
.econofacts-wrap {
  .recent-publications-table {
    padding: 0 30px;
  }
}
.economist-profile-main .economist-profile-header h2{
  &:hover{
    text-decoration: underline!important;
  }
}