.publication-details-main {
  .container {
    padding: 0;
  }

  .publication-details-header{
    .pdf_download{
      background-color: $primaryWhite;
      width: 200px;
      border-radius: 20.6px;
      border: solid 1px $primaryBlue;
      display: inline-block;
      font-family: Heebo;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.18;
      letter-spacing: normal;
      text-align: center;
      color: $primaryBlue;
      padding: 5px 25px;
      margin-top: 15px;
      box-sizing: border-box;
      &:hover{
        box-shadow: 0 0 0 1px $primaryBlue;
        font-weight: 500;
        background: transparent;
      }
    }
  }

  .publication-details-header,
  .publication-details-container {
    padding: 16px;
    margin: 0;
  }

  .publication-details-header {
    margin-right: 25px;

    h1.publication-type {
      color: $primaryBlue;
      text-align: left;
      font-weight: $fontFamilyMedium;
      font-size: 20px;
      margin-bottom: 3px;
    }

    p.publication-date-time {
      @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
      color: $colorGray;
    }

    h3.publication-title {
      text-align: left;
      font-weight: $fontFamilyRegular;
      margin-top: 15px;
      color: $colorGray;
    }
    .publication-title {
      text-align: left;
      font-size: 35px!important;
      font-weight: 400;
      margin-top: 15px;
      @media (max-width: $screen-sm-max) {
        font-size: 20px!important;
      }
    }
  }

  .publication-action-container {
    &.sticky-action {
      position: fixed;
      top: 120px;
      z-index: 1;
    }

    &.bottom-sticky-action {
      position: absolute;
      top: auto;
    }

    .publication-action-button {
      height: 55px;
      width: 55px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 30px;
      cursor: pointer;
      transition: .3s ease-in-out;
      display: block;
      background-color: transparent;
      border: none;

      &.bookmark {
        background-image: url('assets/images/bookmark-publication.png');

        &.active {
          background-image: url('assets/images/bookmark-filled-publication.png');
        }
      }

      &.search {
        background-image: url('assets/images/search-publication.png');

        &.active {
          background-image: url('assets/images/search-publication-filled.png');

          +.custom-tooltip {
            display: block;
          }
        }
      }

      &.font-size {
        background-image: url('assets/images/font-size-publication.png');

        &.active {
          background-image: url('assets/images/font-size-filled-publication.png');

          +.custom-tooltip {
            display: block;
          }
        }
      }

      &.print {
        background-image: url('assets/images/print-publication.png');
      }

      &.mail {
        background-image: url('assets/images/mail-publication.png');
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    .custom-tooltip.font-size {
      background-color: $lightGrayThree;
      padding: 10px;

      &:before,
      &:after {
        background-color: $lightGrayThree;
      }

      .font-change-button {
        @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
        color: $secondaryAccessibleBlue;
        padding: 0 5px;
        margin: 0 5px;
        line-height: 1;

        &.size1 {
          height: 27px;
          margin-top: 13px;
        }

        &.size2 {
          font-size: $DesktopCopyRegular26;
          height: 35px;
          margin-top: 5px;
        }

        &.size3 {
          font-size: $DesktopCopyRegular35;
          height: 40px;
        }

        &.active {
          border-bottom: 2px solid;
        }
      }
    }

    .custom-tooltip.search {
      .search-bar {
        border: none;
        border-bottom: 1px solid $secondarBorderBottomGray;
        width: 300px;
        padding-left: 8px;
      }

      .no-results-to-find {
        color: $colorRed;
        text-align: center;
        padding-bottom: 10px;
      }
    }

    .button-tooltip-container {
      position: relative;
    }
  }

  .publication-details-container {
    padding-top: 0;

    .col {
      padding: 0;
    }

    .handle-search {
      mark {
        padding: 0;
        margin: 0;
        background-color: $current-date-background;
      }
    }

    .left-column,
    .left-column *,
    .right-column,
    .right-column * {
      font-family: $fontfamilyDefault !important;
      p {
        margin-bottom: 1rem;
        word-break: break-word;
      }
    }

    .handle-size-change {
      &.size1 {
        font-size: 88%;
      }

      &.size2 {
        font-size: 100%;
      }

      &.size3 {
        font-size: 125%;
      }
    }

    .left-column {
      img {
        width: 100%;
      }

      .rich-text-container {
        // padding: 15px 0;

        &:first-child {
          padding-top: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          text-align: left;
        }

        a{
          color: $primaryBlue;
          font-weight: 100;
          &:hover, &:focus{
            text-decoration: underline;
          }
        }

        table {
          // to overide the inline styles from BM
          width: 100% !important;
        }
        table tr td {
          padding: 0 5px;
        }
        table tr td img {
          padding: 10px 0;
        }

        @media (max-width: $screen-sm-max) {
          table tr > * {
            display: block;
            width: calc(100vw - 32px) !important;
          }
          table tr td img {
            width: auto !important;
            max-width: calc(100vw - 50px) !important;
          }
          table {
            // to overide the inline styles from BM
            width: calc(100vw - 32px) !important;
          }
        }
      }
    }

    .right-column {
      .row {
        margin: 0 0 15px;
      }

      .right-column-cards {
        .drop-down-icon {
          @include dropDownUpChevron();

          &:before {
            @include dropDownUpChevronBefore();
            color: $primaryBlue;
            transform: rotate(0deg);
            top: 10px;
            left: -5px;
            background-image: url('assets/images/blue-chevron.png');
          }

          &.up {
            &:before {
              transform: rotate(180deg);
              top: 5px;
            }
          }
        }

        .card-header {
          @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
          color: $primaryBlue;
          padding: 5px 0;
          background-color: $primaryWhite;
          border-bottom: solid 1px $secondaryBorderLineGray;
        }

        .card-body {
          padding: 15px 0;
          border-bottom: solid 1px $secondaryBorderLineGray;
        }

       

        &.economist-card {
          .media-body {
            padding-left: 10px;
          }

          img {
            cursor: pointer;
          }

          .economist-name {
            font-weight: $fontFamilyRegular;
            margin-bottom: 5px;
            line-height: normal;
            font-size: $DesktopCopyRegular14;
            a {
                color: $primaryBlue;
            }
          }

          .individual-author-contact {
            @include phoneEmailLinkIcon();
            margin: -2px 0 10px 90px;
          }

          .author-discription {
            font-size: 14px;
            margin: 15px 35px 0 0;
          }

          .read-more-link {
            color: $primaryBlue;
            font-weight: $fontFamilyMedium;
            cursor: pointer;
            font-size: $DesktopCopyRegular14;
            color: $primaryBlue;
          }

          .card-body[data-economistid=''] {

            img,
            .read-more-link,
            .economist-name {
              cursor: default;
            }
          }
        }

        &.related-publication-card {
          .card-body {
            padding: 0;

            .row {
              margin: 0;
              padding: 15px 0;
              border-bottom: solid 1px $secondaryBorderLineGray;
              @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);

              &:nth-last-child(1) {
                border-bottom: none;
              }

              .related-publication-author,
              .related-publication-bio {
                color: $primaryBlue;

                .bio-feature {
                  font-weight: $fontFamilyLight;
                }
              }

              .related-publication-bio {
                margin-top: 10px;
              }

              .related-publication-date,
              .related-publication-subject {
                color: $colorGray;
              }
              .related-publication-date {
                flex: 0 0 80px;
                max-width: 80px;
              }
              .related-publication-author {
                word-break: break-word;
                padding: 0 10px;
                flex: 0 0 calc(50% - 40px);
                max-width: calc(50% - 40px);
              }
              .related-publication-subject {
                word-break: break-word;
                padding: 0 5px;
                flex: 0 0 calc(50% - 40px);
                max-width: calc(50% - 40px);

                @media (max-width: $screen-md-min) {
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            a {
              color: $primaryBlue !important;
            }
          }
        }

        &.key-summary-card {
          ul {
            li {
              list-style: inherit;
              margin: 0 0 15px 20px;
              padding-left: 10px;
              @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
              color: $colorBlue;

              &:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
          }
          .card-body * {
            @include bmoFont($DesktopCopyRegular14, $fontFamilyBold);
            color: $colorBlue;
            text-align: inherit;
            line-height: 1.56;
          }
        }
      }

      .card-column {
        &:nth-child(n+3) {
          display: none;
        }
      }

      .show-card-toggle {
        width: 100%;
        text-align: center;
      }
    }

    .bottom-column {
      border-top: 1px solid $medGray;
      margin-top: 40px;

      .publication-listing-heading {
        text-align: left;
        @include bmoFont($DesktopCopyRegular25, $fontFamilyRegular);
        margin: 30px 0;
        color: $colorGray;
      }

      .publication-result-card:nth-child(n+6) {
        display: none;
      }

      .result-count {
        display: none;
      }

      .publication-result-card:first-child {
        border-top: 1px solid $secondaryBorderPublication;
      }

      .main-publication-table {
        box-shadow: $mobile-filter-options;
      }
      .preview-info-window {
        width: 770px;
        margin-left: 20px;
      }
    }

    .publication-result-card-block-wrap {
      min-height: auto !important;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    .mobile-card{
      display: block !important;
    }
    .desktop-card{
      display: none !important;
    }
  }
  .mobile-card{
    display: none;
  }
  .desktop-card{
    display: block;
  }

  .mobile-action-button {
    position: fixed;
    right: -32px;
    top: 115px;
    background: $primaryBlue;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: $primaryWhite;
    text-align: left;
    padding: 0 0 7px 5px;
    font-size: 32px;
    margin-top: 50px;
    z-index: 1000;
    border: none;
  }

  .modal {
    .modal-dialog {
      @media (max-width: $screen-md-min - 1) {
        margin: 0;
        height: 100vh;
        max-width: 100%;
        border-radius: 0;
      }

      @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
        max-width: 80%;
        top: 50%;
        transform: translateY(-50%);
      }

      .modal-body {
        overflow: scroll;
      }

      .modal-content {
        @media (max-width: $screen-md-min - 1) {
          min-height: 100%;
          border-radius: 0;
          border: 0;
        }
      }

      .close-button-container {
        text-align: right;
        margin-bottom: 10px;
      }

      .close-button {
        width: 25px;
        height: 25px;
        background: $primaryWhite;
        background: url('assets/images/close_icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        margin: 15px;
        border: 0;
      }

      .publication-action-container {
        float: right;

        .custom-tooltip.search.left input {
          width: calc(100vw - 135px);
          max-width: 300px;
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .publication-details-header {
      padding: 20px 30px 0;
    }

    .publication-details-container {
      padding: 0 30px;
    }

    .publication-details-header {
      h1.publication-type {
        font-size: $DesktopCopyRegular30;
        margin: 0 30px 0 0;
        padding-right: 30px;
        border-right: 2px solid $medGray;
      }

      p.publication-date-time {
        font-size: $DesktopCopyRegular16;
      }

      h1.publication-title {
        margin-top: 30px;
        width: calc(100% - 30%);
      }

      .publication-sub-header {
        display: flex;
      }
    }

    .publication-details-container {

      .right-column {
        .card-column {
          padding: 15px 0;
        }

        .right-column-cards:not(.show-card-toggle) {
          box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.13);
          border-radius: 5px;

          .card-header:not(.toggle-cards) {
            color: $primaryWhite;
            background: $primaryBlue;
            padding: 7px 15px;
            font-size: 20px;
          }

          .card-body {
            margin: 15px;

            &:nth-last-child(1) {
              border-bottom: none;
            }
          }

          &.economist-card {
            .media-body {
              padding-left: 20px;
            }

            .media-body,
            .economist-name {
              font-weight: $fontFamilyRegular;
              font-size: $DesktopCopyRegular16;
              margin: 0;
            }

            .align-self-center {
              .economist-division-designation {
                line-height: 1.25;
                margin-bottom: 10px;
              }
            }

            .author-discription {
              margin-right: 0;
              font-size: $DesktopCopyRegular16;
            }

            .read-more-link {
              color: $primaryBlue;
              font-weight: $fontFamilyMedium;
              font-size: $DesktopCopyRegular16;
            }

            .individual-author-contact {
              margin-left: 100px;
            }
          }

          &.related-publication-card {
            .card-body {
              padding: 0;
              margin: 0 15px;
            }
          }

          &.key-summary-card {
            box-shadow: none;

            .card-body {
              padding: 0;
            }

            li,
            .card-body * {
              font-size: $DesktopCopyRegular16;
            }
          }
        }

        .right-column-cards {
          .toggle-cards {
            font-size: $DesktopCopyRegular20;
            padding-bottom: 30px;
            margin-bottom: 25px;
          }

          .drop-down-icon {
            display: inline-block;
            margin: 0 0 2px 10px;
          }
        }
      }

      .bottom-column {
        .publication-listing-heading {
          font-size: $DesktopCopyRegular35;
          margin-top: 40px;
        }

        .econofacts-publications-table {
          padding: 0;

          .publication-result-card {
            padding: 15px 25px;
          }
        }
      }
    }
  }

  @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {

    // overide the bootstrap grid
    .left-column {
      flex: 0 0 88%;
      max-width: 88%;
    }

    .action-column {
      flex: 0 0 12%;
      max-width: 12%;
    }

    .publication-details-container .col.bottom-column {
      margin-left: 12%;
      padding-right: 12%;
      flex: 0 0 88%;
      max-width: 88%;
    }

    .right-column {
      .card-column {

        &.even {
          padding-left: 15px;
        }

        &.odd {
          padding-right: 15px;
        }
      }

      .author-discription,
      .read-more-link {
        display: none !important; // clamp
      }
    }
  }

  @media (min-width: $screen-lg-min) {

    .publication-details-header,
    .publication-details-container {
      padding: 20px 40px;
    }

    .publication-details-header {
      h3.publication-title {
        margin-top: 15px;
      }
    }

    .publication-details-container {
      .left-column {
        padding-right: 20px;
      }

      .right-column {
        padding-left: 20px;

        .economist-card {
          .author-discription {
            display: block;
          }
        }

        .card-column {
          &:nth-child(1) {
            padding-top: 0;
          }

          &:nth-child(n+3) {
            display: block;
          }
        }
      }

      .bottom-column {
        padding-right: 8.333333%;

        .publication-listing-heading {
          margin-top: 70px;
        }
      }
    }
  }

  .focus-sub-title {
    @include bmoFont($DesktopCopyRegular25, $fontFamilyLight);
    color: $colorGray;
  }

  ul {
    padding-left: 40px;
    li {
      list-style: unset;
    }
  }
}

#disclosure-modal {
  h1.modal-title{
    font-size: 25px;
  }

  .close-button-container {
    text-align: right;
  }

  .close-button {
    width: 25px;
    height: 25px;
    background: white;
    background: url('assets/images/close_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
    margin-top: 5px;
    border: 0;
  }
  @media (max-width: $screen-sm-max) {
    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      border: 0;
      border-radius: 0;
    }
  }

  .modal-body p {
    padding-bottom: 20px;
    @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
    a {
      word-break: break-word;
      color: $primaryBlue;
      &:hover, &:focus{
        text-decoration: underline!important;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .modal-content {
      padding: 20px;
    }

    .modal-body p {
      font-size: $DesktopCopyRegular16;
    }
  }
}
.publication-details-main .publication-details-container .right-column{
.right-column-cards.economist-card .economist-name a,
.right-column-cards:not(.show-card-toggle).economist-card .read-more-link,
.right-column-cards.related-publication-card .card-body a,
.right-column .right-column-cards.related-publication-card .card-body a,
.right-column .right-column-cards.related-publication-card .card-body a{
  &:hover, &:focus{
    text-decoration: underline!important;
  }
}
}
.rich-text-container p a{
  &:hover, &:focus{
    text-decoration: underline!important;
  }
}