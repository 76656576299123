/* Publications Update Components */
.publication-update-wrap {
  .publication-update-container {
    padding-top: 55px;
    ul {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
      width: 100%;

      li {
        margin: 0;
        width: 100%;
        -webkit-column-break-inside: avoid;
        /* Chrome, Safari */
        page-break-inside: avoid;
        /* Theoretically FF 20+ */
        break-inside: avoid-column;
        /* IE 11 */
        display: table;
        /* Actually FF 20+ */
      }
    }

    .publication-update-title {
      margin-bottom: 26px;

      h1 {
        margin-bottom: 0;
      }
    }

    .publication-update-header {
      border: none;
      padding: 0px;
      background: none;
      h2 {
        margin-bottom: 16px;
        line-height: 25px;
        color: $secondaryGray;
        @include bmoFont($DesktopCopyRegular16, $fontFamilyMedium);
      }

      img {
        width: 14px;
        height: 8px;
        margin: 6px 0;

        &.arrow-down {
          transform: rotate(180deg);
        }
      }
    }

    .mobile-publication-update{
      display: none;
    }
    .desktop-publication-update{
      display: block;
    }

    .publication-update-body {
      margin-bottom: 16px;

      p {
        @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
        line-height: 20px;

        .publication-date {
          display: block;
          color: $secondaryGray;
          @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
        }
      }

      .publication-name {
        font-weight: $fontFamilyRegular;
      }

      a {
        @include bmoFont($DesktopCopyRegular16, $fontFamilyMedium);
        color: $primaryBlue;
        line-height: 20px;
        cursor: pointer;
      }
    }

    // Only for mobiles
    @media (max-width: $screen-sm-max) {
      .mobile-publication-update{
        display: block;
      }
      .desktop-publication-update{
        display: none;
      }
      li:last-of-type {
        border-bottom: solid 1px $secondaryBorderLineGray;
      }
      .publication-update-header {
        border-top: solid 1px $secondaryBorderLineGray;
        background: white;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 0px;
        padding-top: 10px;
        width: 100%;
      }

      .publication-update-body {
        p:last-of-type {
          margin-bottom: 20px;
        }
      }
    }

    // Tablets onwards
    @media (min-width: $screen-md-min) {
      padding: 55px 30px 0px;

      ul {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
      }

      .publication-update-title {
        margin-bottom: 30px;
      }

      .publication-update-header {
        h5 {
          @include bmoFont($DesktopCopyRegular26, $fontFamilyRegular);
        }
      }

      .publication-update-body {
        width: 90%;
        margin-bottom: 41px;
      }
    }

    // Desktops
    @media (min-width: $screen-lg-min) {
      padding: 75px 35px 0px;

      ul {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
      }

      .publication-update-title {
        margin-bottom: 62px;
      }
    }
  }
}
.publication-update-wrap .publication-update-container .publication-update-body a{
  &:hover, &:focus{
    text-decoration: underline!important;
  }
}

.publication-body{
  margin: 0px 0px 15px 0px;
}
