.recent-releases-main {
  .container {
    padding: 0;
  }

  .recent-releases-header {
    padding: 16px 16px 25px;

    h1 {
      display: inline-block;
      vertical-align: bottom;
      margin: 0 0 2px 5px;
      color: $secondaryGray;
    }
  }

  .economist-details-container {
    background: $secondaryBackgroundGray;
    padding-bottom: 10px;
    margin: 0 0 40px;

    .economist-details {
      padding: 20px 15px;

      img,
      .economist-name {
        cursor: pointer;
        pointer-events: all;
      }

      .media-body {
        pointer-events: none;
      }
    }

    .economist-name {
      color: $primaryBlue;
      text-align: left;
      font-weight: $fontFamilyRegular;
    }

    .economist-designation {
      line-height: 1.38;
    }

    .media-body {
      padding: 5px;
      color: $colorGray;
    }

    .economist-discription {
      padding: 0 15px;
      font-size: $DesktopCopyRegular14;
      line-height: 1.43;
      color: $colorGray;
    }

    .economist-discription-container {
      .show-more-arrow {
        display: none;
      }

      .economist-discription * {
        // here the important is used to overide the rich text inline font.
        font-family: $fontfamilyDefault !important;
      }

      .economist-discription {
        &.show-less {
          height: 40px;
          overflow: hidden;
        }
      }

      .more-data+.show-more-arrow {
        display: block;

        &.rotate-arrow {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .recent-releases-header {
      padding: 22px 30px 10px;

      img {
        width: 70px;
        height: 70px;
      }

      h1 {
        margin: 0 0 10px 5px;
        line-height: 1;
      }
    }

    .economist-details-container {
      display: flex;

      .economist-details {
        display: block;
        margin: 15px 30px 0;

        img {
          width: 185px;
          height: 185px;
        }

        .economist-name {
          margin: 0;
        }

        .economist-name,
        .media-body {
          font-size: $DesktopCopyRegular16;
          text-align: center;
          line-height: 1.33;
          padding-top: 5px;
        }
      }

      .economist-discription-container {
        margin: 30px 30px 0 0;

        .economist-discription {
          font-size: $DesktopCopyRegular16;

          &.show-less {
            height: 243px;
          }
        }

        .show-more-arrow {
          margin-top: 5px;

          img {
            width: 20px;
            height: 10px;
          }
        }
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .recent-releases-header {
      padding: 45px 40px 6px;

      h1 {
        margin-left: 20px;
      }
    }

    .economist-details-container {
      padding-bottom: 25px;

      .economist-details {
        margin: 25px 40px 0 60px;

        .media-body,
        .economist-name {
          font-size: $DesktopCopyRegular18;
        }
      }

      .economist-discription-container {
        margin: 45px 60px 0 0;

        .economist-discription {
          font-size: $DesktopCopyRegular16;

          &.show-less {
            height: 100%;
          }
        }
      }
    }
  }
}
.recent-releases-main ~ .circle-component-wrap {
  .horizontal-divider-wrap {
    display: none;
  }
}
