.trending-articles-main {
  .container {
    padding: 0;
  }

  .trending-articles-header {
    h1 {
      color: $secondaryGray;
      margin: 0;
    }

    margin: 24px 0 10px;
    @media (min-width: $screen-md-min) {
      margin: 50px 0 30px;
    }
  }

  .see-more-button {
    button {
      &:focus {
        border:0;
        outline: $focus-outline !important;
      }
    }
  }

  .econofacts-table-template.trending-articles-table {
    padding-top: 20px;

    .main-publication-table {
      .publication-result-card {
        padding: 16px;

        .left-block {
          z-index: 0 !important;
          .result-column.publication-date {
            width: 40%;
            @media (max-width: $screen-sm-max) {
              vertical-align: top;
            }

            p {
              @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
              color: $colorGray;
            }
          }

          .result-column.publication-author {
            @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
            color: $primaryBlue;
            line-height: 21px;
            @media (max-width: $screen-sm-max) {
              vertical-align: top;
              margin: 0;
            }

            .author-image-name {
              text-align: left;
              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }

            &:hover {
              .author-info-modal {
                display: none;
              }
            }

            /* Show author info window, hover on avatar */
            .author-image-name {
              img {
                display: none;
              }
            }

          }

          .result-column.publication-title {
            width: 100%;
            padding: 0;

            .publication-description {
              display: none;
            }

            .publication-subject-title {
              margin-top: 10px;
              @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
              color: $primaryBlue;
              &:hover,
              &:focus {
                text-decoration: underline;
              }

              span {
                font-weight: $fontFamilyLight;
              }
            }
          }

          .publication-subject {
            display: none;
          }
        }

        .right-block {
          .result-column.publication-preview {
            .preview-icon {
              display: none;
            }
            .mobile-preview-icon:focus {
              outline: 2px dotted $primaryBlue;
            }
            .preview-info-window {
              .author-image-name {
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                color: $primaryBlue;

                span {
                  display: block;
                }
              }
            }
          }
        }

        @media screen and (min-width: $screen-md-min) {
          margin: 0 30px;
          padding: 20px 30px;

          .left-block {
            .result-column.publication-date {
              width: 15%;

              p {
                font-size: $DesktopCopyRegular16
              }
            }

            .result-column {
              vertical-align: middle;
            }

            .result-column.publication-author {
              width: 12%;
              font-size: $DesktopCopyRegular16;
              margin-left: 20px;
              margin-right: 30px;
              text-align: left;

              .author-image {
                span {
                  width: auto;
                }
              }

              .author-info-modal {
                left: 100px;
                top: 50%;
                transform: translateY(-50%);

                .modal-triangle {
                  @include modalTriangle();
                }
              }

              &:hover {
                @media (min-width: $screen-tab + 1) {
                  .author-info-modal {
                    display: block;
                  }
                }
              }
            }

            .result-column.publication-title {
              width: 60%;

              .publication-subject-title {
                font-size: $DesktopCopyRegular18;
              }
            }
          }

          .right-block {
            right: 30px;

            .result-column.publication-preview {
              .bookmark-icon {
                display: inline-block;
                &:focus {
                  outline: $focus-outline;
                }
              }

              .preview-icon {
                display: none;
              }
            }
          }
        }

        @media screen and (min-width: $screen-windows-md) {
          .left-block {
            .result-column.publication-author {
              margin-right: 50px;
              text-align: left;
              padding: 15px 0;
            }

            .result-column.publication-author {
              .author-info-modal {
                left: calc(100% - 1px);
              }
            }
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          .right-block {
            .result-column.publication-preview {
              .mobile-preview-icon {
                display: none;
              }

              .preview-icon {
                display: inline-block;
                float: inherit;
                &:focus {
                  outline: $focus-outline;
                }
              }
            }
          }
        }
      }

      .publication-result-card:nth-child(odd) {
        background-color: $lightGrayThree;
        a{
          color: #056fad !important;
        }
      }

      .publication-result-card:first-child {
        border-top: .5px solid $secondaryBorderPublication;
      }
    }
  }
}
