/* Component specific mixins */
@mixin modalTriangle() {
  position: absolute;
  width: 0;
  height: 0;
  left: -18px;
  top: 45%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid $primaryWhite;
  display: inline-block;
  transform: rotate(-90deg);
}

@mixin authorImageName {
  display: inline-block;
  text-align: center;
  color: $primaryBlue;

  @media (max-width: $screen-md-min) {
    font-size: $MobileCopyRegular14;
  }

  @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);

  img {
    @media (max-width: $screen-tab - 1) {
      display: none;
    }

    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  span {
    display: block;
    word-break: break-word;
    width: 90px;
    @media (max-width: $screen-sm-max) {
      width: auto;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

@mixin triangleArrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 9px solid $primaryWhite;
  display: inline-block;
  margin-left: 6px;
}

@mixin filterOptionCheck {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  float: right;
  position: relative;
  transform: rotate(-45deg);
  vertical-align: top;
  color: $primaryWhite;
}

@mixin filterCheckBlue {
  top: 3px;
  transform: rotate(135deg);
  border-width: 2px 2px 0 0;
  height: 8px;
  width: 15px;
  color: $colorBlue;
}

@mixin previewIcon {
  background-size: contain;
  padding: 0;
  height: 18px;
  width: 29px;
  background-position: 50%;
  background-repeat: no-repeat;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

@mixin bookmarkIcon {
  @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
    margin-left: 20px;
  }
  @media (max-width: $screen-lg) { 
    margin-left: 10px;
  }

  margin: 0;
  border-radius: 0;
  background-size: contain;
  padding: 0;
  height: 23px;
  width: 17px;
  margin-left: 30px;
  background-repeat: no-repeat;

  &.bookmark-active {
    background-image: url('assets/images/bookmark-fill.png');

    &:focus {
      outline: $focus-outline;
    }
  }

  &.bookmark-inactive {
    background-image: url('assets/images/bookmar_path@2x.png');
    &:hover{
      background-image: url('assets/images/bookmark-hover.png');
    }

    &:focus {
      border:0;
      outline: $focus-outline;
    }
  }
}

/* Econofacts and Publications list table */
.econofacts-table-template, .publications-table-template {
  .econofacts-icons-block, .publications-icons-block {
    padding: 15px 15px 25px;

    @media (min-width: $screen-md-min) {
      padding: 0 30px;
    }

    @media (min-width: $screen-tab) {
      padding: 42px 25px 55px;
    }

    @media (min-width: $screen-xl-min) {
      padding: 42px 40px 55px;
    }

    .econofacts-heading, .publications-heading {
      @media (max-width: $screen-sm-max) {
        padding: 0 0 25px;
        border-bottom: 1px solid $secondaryFilterBorder;
      }

      @media (min-width: $screen-md-min) {
        padding: 40px 0 45px;
      }

      @media (min-width: $screen-tab) {
        padding: 0;
      }

      .econofacts-icon, .publications-icon {
        display: inline-block;
        img {
          @media (max-width: $screen-sm-max) {
            width: 50px;
            height: 50px;
          }

          width: 70px;
          height: 70px;
          display: block;
        }
      }

      h1 {
        color: $secondaryGray;
        display: inline-block;
        margin-left: 10px;
        vertical-align: top;
        margin-bottom: 0;
        @include bmoFont($DesktopCopyRegular25, $fontFamilyLight);
        @media (max-width: $screen-sm-max) {
          margin-top: 5px;
        }
        @media (min-width: $screen-md-min) {
          margin-left: 20px;
          font-size: $DesktopCopyRegular50;
        }
      }
    }
  }

  .recent-publications-header {
    padding: 16px;

    h1 {
      color: $secondaryGray;
    }

    .recent-publications {
      @include bmoFont($DesktopCopyRegular22, $fontFamilyLight);
      color: $secondaryGray;
      padding-left: 0;

      @media (min-width: $screen-md-min) {
        padding-left: 10px;
      }
      @media (min-width: $screen-xl-min) {
        padding-left: 23px;
      }
    }

    @media (min-width: $screen-md-min) {
      padding: 30px;

      .recent-publications {
        font-size: $DesktopCopyRegular35;
      }
    }
  }

  /* Econofacts accordion filter */
  .econofacts-publications-table {
    padding: 0;
    margin-bottom: 55px;

    @media (max-width: $screen-sm-max) {
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $screen-md-min) {
      padding: 0 30px;
      margin-bottom: 100px;
    }

    @media (min-width: $screen-tab) {
      padding: 0 30px;
    }

    @media (min-width: $screen-xl-min) {
      padding: 0 40px;
    }

    #sidebar {
      @media (max-width: $screen-sm-max) {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 25px;
      }

      padding: 0;
      width: 250px;
      position: relative;
      .sidebar__inner {
        @media (max-width: $screen-sm-max) {
          position: relative !important;
          transform: translate3d(0, 0, 0) !important;
          top: 0 !important;
          width: 100% !important;
        }

        @media (min-width: $screen-md-min) {
          position: absolute;
          float: left;
          width: 250px;
        }
        .card {
          border: 0;
          border-radius: 0;

          &:first-child {
            .card-header {
              @include borderRadiusTopLeftRight();
              border-bottom: 1px solid $primaryWhite;
            }
          }

          &:last-child {
            .card-header {
              @include borderRadiusBottomLeftRight();
              .btn {
                @include noBorderRadiusTopLeftRight();
              }
              .btn[aria-expanded="true"] {
                @include noBorderRadiusBottomLeftRight();
              }
            }
          }

          &:nth-child(1) {
            .card-header {
              .btn {
                border-radius: 0;
                @include borderRadiusTopLeftRight();
              }
            }
          }

          &:nth-child(2) {
            .card-header {
              border-bottom: 1px solid $primaryWhite;
              .btn {
                border-radius: 0;
                text-transform: uppercase;
              }
            }
          }

          .card-header {
            padding: 0;
            border-radius: 0;
            border-bottom: 0;
            cursor: pointer;

            @media (max-width: $screen-sm-max) {
              background: $colorBlue;
            }

            .btn {
              width: 100%;
              text-align: left;
              padding: 10px 0 7px 15px;
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              color: $primaryWhite;
              text-transform: capitalize;
              background: $primaryBlue;

              &:hover,
              &:focus {
                text-decoration: none;
                outline: $focus-outline;
              }

              .drop-down-icon-acc {
                position: relative;
                float: right;
                content: "";
                display: block;
                height: 15px;
                width: 15px;
                background-size: cover;
                background-position: 50%;

                &:before {
                  border-style: solid;
                  border-width: 1px 1px 0 0;
                  content: "";
                  display: inline-block;
                  height: 10px;
                  position: relative;
                  top: 8px;
                  right: 22px;
                  transform: rotate(-45deg);
                  vertical-align: top;
                  width: 10px;
                  color: $primaryWhite;
                }
              }

              &.collapsed {
                .drop-down-icon-acc {
                  &:before {
                    transform: rotate(135deg);
                    top: 3px;
                  }
                }
              }
            }

            .btn[aria-expanded="true"] {

              @media (max-width: $screen-sm-max) {
                background: $primaryBlue;
              }
            }
          }

          .collapse-body {
            height: 100%;
          }

          .card-body {
            padding: 0;

            ul {
              border: 1px solid $secondayBorderLightGray;
              border-bottom: 0;
              border-top: 0;

              li {
                border: 0;
                margin: 0;
                padding: 8px 23px 6px 15px;
                border-radius: 0;
                border-bottom: 1px solid $secondaryEconofactsBorderLineGray;
                margin: 1px 0 4px;
                cursor: pointer;

                &.bold-text {
                  font-weight: $fontFamilyMedium;
                }

                p {
                  display: inline-block;
                  @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
                  color: $colorGray;
                }

                /* Filter options check */
                .bmo_chevron {
                  display: none;

                  &:before {
                    @include filterOptionCheck();
                  }

                  &.tick {
                    &:before {
                      @include filterCheckBlue();
                    }
                  }
                }

                &.selected-filter {
                  p {
                    font-weight: $fontFamilyMedium;
                  }

                  .bmo_chevron {
                    display: inline;
                  }
                }
              }
            }
          }
        }
      }
    }

    /* Econofacts table */
    .main-publication-table {
      .publication-result-card-block-wrap {
        min-height: 200px;
        height: auto;
      }
      position: relative;
      @media (max-width: $screen-sm-max) {
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-top: 20px;
      }

      width: calc(100% - 250px);
      max-width: calc(100% - 250px);
      padding-left: 20px;
      padding-right: 0;

      .result-count {
        position: absolute;
        top: -30px;
        left: 30px;
        @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
        color: $colorGray;
        strong {
          font-weight: $fontFamilyMedium;
        }
      }

      .filter-bar {
        background: $primaryBlue;

        @media (min-width: $screen-md-min) {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        @media (max-width: $screen-tab - 1) {
          display: none;
        }

        ul {
          padding: 0 15px 0 25px;

          @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
            padding: 0 15px;
          }

          li {
            display: inline-block;
            padding: 7px 0;

            &.date {
              width: 10%;
              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 13%;
              }
            }

            &.author {
              width: 10%;
              text-align: center;
              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 14%;
                text-align: center;
                padding-left: 0;
              }
              @media (min-width: $screen-windows-lg) {
                text-align: center;
                padding-left: 10px;
              }
            }

            &.title {
              width: 45%;
              padding-left: 25px;

              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 29%;
                padding-left: 20px;
              }

              @media (min-width: $screen-windows-lg) {
                padding-left: 35px;
              }
            }

            &.subject {
              width: 13%;
              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 17%;
                padding-left: 5px;
              }
              @media (min-width: $screen-windows-lg) {
                padding-left: 10px;
              }
            }

            &.show-less {
              width: 19%;
              text-align: right;

              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 24%;
              }

              .drop-down-icon {
                @include dropDownUpChevron();

                &:before {
                  @include dropDownUpChevronBefore();
                  top: 10px;
                  background-image: url('assets/images/chevron_down.png');
                }

                &.down {
                  &:before {
                    top: 5px;
                    transform: rotate(-180deg);
                  }
                }
              }
            }

            .btn {
              padding: 0;

              &:focus {
                outline: 2px solid $primaryWhite;
              }

              span {
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                color: $primaryWhite;
              }

              em.icon {
                @include triangleArrow();
              }

              &.sort-active {
                span {
                 border-bottom: 1px solid $primaryWhite;
                 display: inline-block;
                }
              }

              em.icon.caret.up {
                transform: rotate(0);
              }

              em.icon.caret.down {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      /* Mobile view filter */
      .filter-bar-select {
        @media (min-width: $screen-tab) {
          display: none;
        }

        .title-bar {
          background: $primaryBlue;
          padding: 7px 15px;
          @media (min-width: $screen-md-min) {
            padding: 7px 15px 7px 25px;
          }

          @media (max-width: $screen-tab - 1) {
            @include borderRadiusTopLeftRight();
          }

          @media (max-width: $screen-md-min - 1) {
            @include noBorderRadiusTopLeftRight();
          }

          p {
            @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
            color: $primaryWhite;
            display: inline-block;

            .mobile-filter-btn {
              padding: 0 5px;
              vertical-align: bottom;
              color: $primaryWhite;

              em.icon {
                @include triangleArrow();
              }

              em.icon.caret.up {
                transform: rotate(0);
              }

              em.icon.caret.down {
                transform: rotate(-180deg);
              }
            }
          }

          .mobile-filter-dropdown {
            float: right;

            .drop-down-icon {
              @include dropDownUpChevron();

              &:before {
                @include dropDownUpChevronBefore();
                background-image: url('assets/images/chevron_down.png');
                width: 18px;
                transform: rotate(-180deg);
                top: -2px;
              }

              &.down {
                &:before {
                  transform: rotate(0);
                  top: 2px;
                }
              }
            }
          }
        }

        .mobile-filter-options {
          border: 1px solid $secondaryBorderPublication;
          box-shadow: $mobile-filter-options;

          li {
            padding: 15px 25px;
            border-bottom: 1px solid $secondaryBorderPublication;
            @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
            color: $colorGray;
            width: 100%;
            text-align: left;

            &:last-child {
              border: 0;
            }

            /* Option check */
            .bmo_chevron {
              display: none;
              margin-left: 20px;

              &:before {
                @include filterOptionCheck();
                float: inherit;
              }

              &.tick {
                &:before {
                  @include filterCheckBlue();
                  top: 5px;
                }
              }
			}

			p.selected-filter {
				font-weight: $fontFamilyMedium;
				.bmo_chevron {
					display: inline;
				}
			}

            // &.selected-filter {
            // font-weight: $fontFamilyMedium;

            //   .bmo_chevron {
            //     display: inline;
            //   }
            // }
          }
        }
      }

      /* Publication card */
      .publication-result-card {
        padding: 10px;
        border: 1px solid $secondaryBorderPublication;
        border-top: 0;
        position: relative;

        @media (min-width: $screen-windows-lg) {
          padding: 25px 20px;
        }

        .left-block {
          width: 90%;
          display: inline-block;

          @media (min-width: $screen-tab) {
            width: 85%;
          }

          .result-column {
            display: inline-block;
            vertical-align: middle;

            &.publication-date {
              @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
                width: 26%;
                vertical-align: top;
              }

              @media (max-width: $screen-sm-max) {
                width: 30%;
                vertical-align: top;
              }

              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 16%;
              }

              width: 12%;

              p {
                @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
                color: $colorGray;
              }
            }

            &.publication-author {
              @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
                width: 25%;
              }

              @media (max-width: $screen-sm-max) {
                width: 30%;
                padding-left: 10px;
                vertical-align: top;
                margin-top: -3px;
              }

              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 15%;
                margin: 0 auto;
              }

              @media only screen and (min-width: $screen-md-min) and (max-width: $screen-tab) and (orientation: landscape) {
                width: 17%;
                margin: 0 auto;
                vertical-align: top;
              }

              width: 14%;
              position: relative;
              text-align: center;

              @media (min-width: $screen-windows-lg) {
                margin-right: 10px;
              }

              .author-image-name {
                @include authorImageName();

                &:focus,
                &:hover {
                  text-decoration: underline;
                }

                img {
                  margin-bottom: 5px;
                }
              }

              &:hover {
                @media (min-width: $screen-tab + 1) {
                  .author-info-modal {
                    display: block;
                  }
                }
              }

              .author-name-mobile {
                display: block;
                font-size: $MobileCopyRegular14;

                @media (min-width: $screen-md-min) {
                  display: none;
                }
              }

              /* Author info modal*/
              .author-info-modal {
                @media (max-width: $screen-sm-max) {
                  display: none;
                }

                display: none;
                position: absolute;
                width: 350px;
                min-height: 153px;
                border-radius: 7px;
                box-shadow: $economist-info-window;
                padding: 20px;
                background: $primaryWhite;
                z-index: $zindex-sticky;

                &.author-info-modal-less {
                  top: calc(50%);
                  transform: translateY(-50%);
                }

                @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
                  right: 130px;
                  top: calc(50%);
                  transform: translateY(-50%);
                }

                @media screen and (min-width: $screen-tab + 1) {
                  left: 103px;
                  top: calc(50%);
                  transform: translateY(-50%);
                }

                @media only screen and (max-width: $screen-tab) {
                  left: 100px;
                  top: calc(50%);
                  transform: translateY(-50%);
                }

                @media screen and (min-width: $screen-windows-md) {
                  left: 110px;
                }

                @media screen and (min-width: $screen-windows-lg) {
                  left: 115px;
                }

                .modal-triangle {
                  @media (max-width: $screen-sm-max) {
                    display: none;
                  }

                  @media (min-width: $screen-md-min) {
                    @include modalTriangle();
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $primaryWhite;
                    left: 98%;
                    transform: rotate(-271deg);
                  }

                  @media (min-width: $screen-tab) {
                    @include modalTriangle();
                  }
                }

                .author-name-block {
                  position: relative;
                  padding: 15px 0;
                  border-bottom: 1px solid $secondaryBorderPublication;

                  &:last-child {
                    border: 0;
                  }

                  .author-name {
                    width: 80%;
                    margin-bottom: 15px;
                  }

                  a {
                    @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                    color: $primaryBlue;

                    &:hover,
                    &:focus {
                      text-decoration: underline;
                    }
                  }

                  p,
                  span {
                    @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                    color: $colorGray;
                    margin-top: 10px;
                  }

                  .individual-author-contact {
                    @include phoneEmailLinkIcon();
                    .btn {
                      &:focus {
                        border: 0;
                        outline: $focus-outline;
                      }
                    }
                  }

                  .arrow-link-detail {
                    width: 25px;
                    height: 25px;
                    top: 20px;
                    right: 0;
                    position: absolute;
                    background-image: url('assets/images/arrow_link.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    &:hover{
                      cursor: pointer;
                      background-image: url('assets/images/arrow-hover.png');
                    }
                  }

                  .economist-designation, .economist-division-name {
                    font-size: $DesktopCopyRegular14;
                    color: $colorGray;
                  }
                }

                .author-list {
                  ul li {
                    padding: 0;
                    text-align: left;

                    a {
                      @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                      color: $primaryBlue;
                      display: block;
                      padding: 15px 0 15px 15px;
                      border-bottom: 1px solid $secondaryBorderPublication;

                      &:hover,
                      &:focus {
                        text-decoration: underline;
                      }

                      .arrow-link-detail {
                        width: 25px;
                        height: 25px;
                        float: right;
                        display: inline-block;
                        background-image: url('assets/images/arrow_link.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                        &:hover{
                          cursor: pointer;
                          background-image: url('assets/images/arrow-hover.png');
                        }
                      }
                    }

                    &:last-child {
                      a {
                        border-bottom: 0;
                      }
                    }
                  }
                }

                .author-info-modal-template {
                  border-bottom: 1px solid $secondaryPublicationsBorderLineGray;

                  &:last-child {
                    border-bottom: 0;
                  }
                }
              }
            }

            &.publication-subject {
              @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
                width: 45%;
                padding-left: 10px;
              }

              @media (max-width: $screen-sm-max) {
                width: 35%;
                vertical-align: top;
              }

              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 18%;
                margin-left: 20px;
              }

              @media only screen and (min-width: $screen-md-min) and (max-width: $screen-tab) and (orientation: landscape) {
                width: 18%;
                margin-left: 5px;
              }

              width: 16%;

              .subject-title {
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                color: $primaryBlue;

                @media only screen and (min-width: $screen-md-min) and (max-width: $screen-tab) and (orientation: landscape) {
                  display: block;
                  padding-left: 10px;
                }
                &:hover {
                  text-decoration: none;
                }

                @media (max-width: $screen-tab - 1) {
                  display: none;
                }
              }

              .publication-title-text {
                display: inline-block;
                @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
                color: $secondaryGray;
                @media (max-width: $screen-md-min) {
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                @media (min-width: $screen-tab) {
                  display: none !important;
                }
              }

            }

            &.publication-title {
              @media (max-width: $screen-sm-max) {
                margin-top: 8px;
                width: 100%;
                padding: 0;
              }
              @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
                width: 100%;
                padding: 0;
                margin-top: 14px;
              }

              @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
                width: 42%;
                padding-left: 20px;
              }

              width: 52%;
              padding-left: 10px;
              color: $colorGray;

              p {
                display: inline-block;
              }
              .publication-subject-title {
                display: block;
                cursor: pointer;
                margin-bottom: 10px;
                color: $primaryBlue;
                line-height: 1.25;
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);

                &:focus,
                &:hover {
                  text-decoration: underline;
                }
              }

              .publication-description {
                @media (max-width: $screen-tab - 1) {
                  display: none;
                }

                @include bmoFont($DesktopCopyRegular14, $fontFamilyRegular);
                color: $colorGray;
              }
            }
          }
        }

        /* Right block */
        .right-block {
          width: 14%;
          display: inline-block;

          @media (max-width: $screen-tab - 1) {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }

          .result-column {
            display: inline-block;
            vertical-align: middle;

            &.publication-preview {
              width: 100%;
              text-align: right;
              position: relative;

              .preview-icon {
                @media (max-width: $screen-tab - 1) {
                  display: none;
                }

                @include previewIcon();

                &.preview-inactive {
                  background-image: url('assets/images/preview.png');
                  &:hover{
                    background-image: url('assets/images/eye-hover.png');
                  }
                }

                &.preview-active {
                  background-image: url('assets/images/preview-fill.png');
                  border: 1px dotted $primaryBlue;
                }
                @media screen and (min-width: $screen-lg-min) {
                  &:focus {
                    outline: $focus-outline;
                  }
                }
              }

              /* Mobile preview icon */
              .mobile-preview-icon {
                display: inline-block;
                &:focus {
                  outline: $focus-outline;
                }

                @media (min-width: $screen-tab) {
                  display: none;
                }

                @include previewIcon();

                &.mobile-preview-inactive {
                  background-image: url('assets/images/preview.png');
                }

                &.mobile-preview-active {
                  background-image: url('assets/images/preview-fill.png');
                }
              }

              .bookmark-icon {
                // @media (max-width: $screen-tab - 1) {
                //   display: none;
                // }

                @include bookmarkIcon();
              }

              /* Preview info window */
              .preview-info-window {
                width: 790px;
                position: absolute;
                padding: 40px;
                left: calc(100% - 880px);
                top: calc(50%);
                transform: translateY(-47%);
                background: $primaryWhite;
                box-shadow: $preview-window-block;
                z-index: $zindex-sticky;

                @media (min-width: $screen-xl-min) {
                  left: calc(100% - 900px);
                }

                .modal-triangle {
                  display: none;

                  @media (min-width: $screen-tab) {
                    @include modalTriangle();
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $primaryWhite;
                    left: 99.2%;
                    transform: rotate(-271deg);
                  }
                }

                .close-icon {
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: 15px;
                  right: 10px;
                  background-image: url('assets/images/close_icon.png');
                  background-repeat: no-repeat;
                  background-size: contain;

                  &:hover,
                  &:focus {
                    box-shadow: none;
                  }
                  @media screen and (min-width: $screen-lg-min) {
                    &:focus {
                      outline: $focus-outline;
                    }
                  }
                }

                .economist-details-left-section {
                  width: 40%;

                  .author-image-name {
                    color: $primaryBlue;

                    &:focus,
                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  .author-preview-image-name, .author-image-name {
                    @include authorImageName();
                    text-align: left;

                    span {
                      width: auto !important;

                      &:hover{
                      text-decoration: underline!important;
                      }
                    }

                    img {
                      margin-bottom: 20px;
                    }
                  }
                  .economist-division_name {
                    display: block;
                  }

                  p {
                    @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
                    color: $colorGray;
                  }

                  .preview-mail-phone {
                    margin-top: 20px;
                    .preview-author-email, .preview-author-phone{
                      margin-right: 20px;
                      display: inline-block;
                    }
                    .btn {
                      width: 21px;
                      height: 21px;
                      background-repeat: no-repeat;

                      &.mail-icon {
                        background-image: url('assets/images/mail-icon.png');
                        background-size: contain;
                        &:hover{
                          background-image: url('assets/images/envelope-hover.png');
                        }
                      }

                      &.phone-icon {
                        background-image: url('assets/images/phone-icon.png');
                        background-size: contain;
                        &:hover{
                          background-image: url('assets/images/phone-hover.png');
                        }
                      }
                      &:focus {
                        border: 0;
                        outline: $focus-outline;
                      }
                    }
                  }
                }

                .publication-right-section {
                  width: 58%;

                  a {
                    @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                    color: $primaryBlue;
                    margin-bottom: 20px;
                    display: block;

                    &:hover,
                    &:focus {
                      text-decoration: underline;
                    }
                  }

                  p {
                    @include bmoFont($DesktopCopyRegular16, $fontFamilyLight);
                    color: $colorGray;

                    a {
                      @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                      color: $primaryBlue;
                      display: inline-block;

                      &:hover,
                      &:focus {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // Full width table
      &.full-width-table {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      /* Preview mobile modal */
      .author-modal-mobile {
        .modal-dialog {
          @media (max-width: $screen-md-min - 1) {
            margin: 0;
            height: 100vh;
            max-width: 100%;
            border-radius: 0;
          }

          @media screen and (min-width: $screen-md-min) and (max-width: $screen-tab - 1) {
            max-width: 80%;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .modal-content {
          @media (max-width: $screen-md-min - 1) {
            min-height: 100%;
            border-radius: 0;
            border: 0;
          }

          .close {
            @include modalCloseMobile();
          }

          .modal-body {
            padding: 30px 15px;

            .author-modal-card {
              margin-bottom: 30px;

              img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
              }

              .author-modal-name {
                @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;
                margin-top: 5px;
                width: 60%;

                a {
                  color: $primaryBlue;
                }

                p {
                  color: $colorGray;
                  font-weight: $fontFamilyLight;
                }

                span.economist-division_name {
                  display: block;
                }

                /* Author mail phone */
                .author-modal-email-phone {
                  margin-top: 10px;

                  .btn {
                    width: 21px;
                    height: 21px;
                    margin-right: 20px;
                    background-repeat: no-repeat;

                    &.mail-icon {
                      background-image: url('assets/images/mail-icon.png');
                      background-size: contain;
                      &:hover{
                        background-image: url('assets/images/envelope-hover.png');
                      }
                    }

                    &.phone-icon {
                      background-image: url('assets/images/phone-icon.png');
                      background-size: contain;
                      &:hover{
                        background-image: url('assets/images/phone-hover.png');
                      }
                    }
                    &:focus {
                      display: none;
                    }
                  }
                }
              }
            }

            .author-modal-publication {
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              padding-top: 20px;
              position: relative;
              &::before {
                content: '';
                width: 100%;
                height: 1px;
                border-top: 1px solid rgba(140, 140, 140, 0.5);
                position: absolute;
                top: 0;
              }

              .publication-modal-date {
                @media (max-width: $screen-sm-max) {
                  width: 80%;
                }

                display: inline-block;

                span {
                  color: $colorGray;
                  font-weight: $fontFamilyLight;
                }

                a {
                  display: block;
                  color: $primaryBlue;
                }
              }

              .btn.bookmark-icon {
                @include bookmarkIcon();
                float: right;
              }
            }

            .full-version {
              line-height: 30px;
              color: $primaryBlue;
              display: block;
              margin: 0 auto;
              margin-top: 30px;
            }
          }
        }
      }

      .load-more-publications {
        @include loadMorePublicationBtn();
        &:focus {
          border: 0;
          outline: $focus-outline;
        }
      }
    }
    p.no-result-text {
      @include bmoFont($DesktopCopyRegular20, $fontFamilyLight);
      color: $secondaryGray;
      padding-top: 80px;
    }
  }
}

.economist-details-table {
  .filter-bar {
    ul li {
      &.author {
        @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
          width: 9% !important;
          padding-left: 10px !important;
          text-align: center !important;
        }
        @media (min-width: $screen-windows-md) {
          width: 8% !important;
        }
        @media (min-width: $screen-windows-lg) {
          padding-left: 0 !important;
        }
      }
      &.title {
        @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
          padding-left: 10px !important;
        }
        @media (min-width: $screen-windows-lg) {
          padding-left: 10px !important;
        }
        padding-left: 10px !important;
        @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 35% !important;
        }
      }
      &.subject {
        @media (min-width: $screen-windows-md) {
          width: 14% !important;
        }
        @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
          width: 14% !important;
          padding-left: 15px !important;
        }
        @media (min-width: $screen-windows-lg) {
          padding-left: 15 !important;
        }
      }
      &.show-less {
        width: 20% !important;
        @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
          width: 32% !important;
        }
        @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 27% !important;
        }
        @media (min-width: $screen-windows-lg) {
          @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            
          }
        }
      }
    }
  }
  .publication-result-card-block-wrap {
    min-height: 150px;
    .publication-result-card {
      .left-block {
        .result-column {
          &.publication-author {
            @media (min-width: $screen-tab) {
              width: 10% !important;
            }
          }
          &.publication-subject {
            @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
              width: 14% !important;
              padding-left: 20px !important;
              margin-left: 0 !important;
            }
            @media (min-width: $screen-windows-md + 1) {
              padding-left: 10px !important;
            }
          }
          &.publication-title {
            @media screen and (min-width: $screen-tab) and (max-width: $screen-windows-md) {
              padding-left: 30px !important;
            }
          }
        }
      }
    }

    .publication-author {
      .author-info-modal {
        left: calc(100% - 0px) !important;

        .modal-triangle {
          @media (max-width: $screen-sm-max) {
            display: none;
          }

          @media (min-width: $screen-md-min) {
            border-left: 12px solid transparent !important;
            border-right: 12px solid transparent !important;
            border-bottom: 12px solid $primaryWhite !important;
            left: -18px !important;
            transform: rotate(-90deg) !important;
          }

          @media (min-width: $screen-tab) {
            @include modalTriangle();
          }
        }
      }
    }
  }
}

.econofacts-wrap,
.trending-articles-main {

  .see-all-button,
  .see-more-button {
    text-align: center;

    button {
      margin-top: 40px;
      margin-bottom: 31px;

      @media (min-width: $screen-md-min) {
        margin-top: 50px;
        margin-bottom: 4px;
      }

      @media (min-width: $screen-lg-min) {
        margin-bottom: 25px;
      }
      &:focus {
        border: 0;
        outline: $focus-outline;
      }
    }
  }
  .see-more-button {
    text-align: center;
    button {
      margin-top: 50px;
      margin-bottom: 0;
    }
  }
}
