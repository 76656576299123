@mixin split-column() {
  // This is to split the footer to multiple columns
  height: 100px;
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;

  li {
    width: 55%;
  }

  @media screen and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    height: 120px;
  }

  @supports (-ms-ime-align: auto) {
    /* IE Edge 12+ CSS styles go here */
    height: 120px;
  }
}

.footer-desktop{
  display: block;
}
.footer-mobile{
  display: none;
}

@media screen and (max-width: $screen-sm-max) {
  .footer-desktop{
    display: none;
  }
  .footer-mobile{
    display: block;
  }
}

footer {
  margin-top: 70px;
  @media (min-width: $screen-md-min) {
    margin-top: 80px;
  }
  @media (min-width: $screen-lg-min) {
    margin-top: 100px;
  }
  .footer-desktop{
    display: block;
  }
  .footer-mobile{
    display: none;
  }
  .footer-block-row {
    .footer-sub-block-row {
      .footer-sub-block {
        padding: 0;

        ul {
          li {
            @media (min-width: $screen-md-min) {
              margin-bottom: 3px;
            }

            a {
              @include bmoFont($DesktopCopyRegular16, $fontFamilyRegular);
              line-height: 1.5;

              &:hover {
                text-decoration: none;
                color: $primaryWhite;
                border-bottom: 1px solid $primaryWhite;
              }
              &:focus {
                outline: 2px solid $primaryWhite;
              }
              @media (min-width: $screen-md-min) {
                color: $primaryWhite;
              }
            }
          }
        }

        .footer-sub-heading {
          pointer-events: none;
          line-height: 1;
          @include bmoFont($DesktopCopyRegular16, $fontFamilyBold);
          background: none;
          border: none;
          padding: 0;
        }

        @media screen and (max-width: $screen-sm-max) {
          border-bottom: 0.5px solid $primaryWhite;
          .footer-desktop{
            display: none;
          }
          .footer-mobile{
            display: block;
          }

          .footer-sub-heading {
            padding: 0.8125rem 0.9375rem 0.8125rem 0.8125rem;
            margin: 0;
            font-weight: $fontFamilyLight;
            width: 100%;
            pointer-events: auto;

            i {
              padding-top: 0.5rem;
            }
          }

          ul {
            background: white;

            li:not(:last-child) {
              border-bottom: 1px solid $medGray;
            }

            li {
              padding: 0.9375rem 0.9375rem;

              a {
                font-size: $DesktopCopyRegular14;
                color: $primaryBlue;
              }
            }
          }
        }

        @media screen and (min-width: $screen-md-min) {
          .footer-sub-heading {
            margin-bottom: 1.05rem;
          }

          ul {
            padding-right: 1.25rem
          }

          ul.split-ul {
            @include split-column();

            li {
              width: 45%;
            }
          }
        }

        @media screen and (min-width: $screen-lg-min) {
          .footer-sub-heading {
            margin-bottom: 0.5rem;
            background: none;
            border: none;
            padding: 0;
          }

          ul {
            padding-right: 1.25rem
          }

          ul.split-ul {
            @include split-column();

            li {
              width: 50%;
            }
          }
        }

        .drop-down-icon {
          @include dropDownUpChevron();

          &::before {
            @include dropDownUpChevronBefore();
            transform: rotate(0);
            top: 0;
            left: 0;
            background-image: url('assets/images/chevron_down.png');
          }

          &.down {
            &::before {
              top: 5px;
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    .footer-logo {
      margin-top: 1.75rem;
      margin-bottom: 1.5625rem;

      @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
      }

      a {
        display: inline-block;
        width: 206px;
        height: 37px;
        &:focus {
          outline: 2px solid $primaryWhite;
        }
      }

      img {
        height: 37px;

        @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }

      a {
        span {
          margin-left: 11px;
          color: $primaryWhite;
          vertical-align: middle;

          @media (max-width: $screen-sm-max) {
            margin-left: 8px;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }

      @media screen and (min-width: $screen-md-min) {
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
      }

      @media screen and (min-width: $screen-lg-min) {
        margin-bottom: 52px;
        margin-top: 47px;
      }
    }

    @media screen and (min-width: $screen-md-min) {
      padding: 1.75rem 2.1875rem;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding: 2.5rem 1.875rem;
    }
  }
}
