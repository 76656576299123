.our-economists {
  .container {
    padding: 0;
  }

  .our-economists-header {
    padding: 33px 16px 11px;

    img {
      width: 50px;
      height: 50px;
    }

    h1 {
      color: $secondaryGray;
      display: inline-block;
      @include bmoFont($DesktopCopyRegular25, $fontFamilyLight);
      margin: 0 0 0 10px;
      vertical-align: middle;
    }
  }

  .our-economist-divider {
    @include horizontalLine(calc(100% - (16px * 2)));
  }

  .top-economists-container {
    padding: 16px;

    .media {
      margin-bottom: 35px;
    }

    .economist-details-container {
      padding: 0 15px;

      .economist-name,
      .economist-designation {
        @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
      }

      .economist-name {
        color: $primaryBlue;
        margin-bottom: 5px;
        a {
          color: $primaryBlue;
          &:focus,
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .economist-designation {
        color: $secondaryGray;
        line-height: 1.19;
        pointer-events: none;
        cursor: default;
      }
    }

    .economist-photo,
    .economist-name {
      cursor: pointer;
    }
  }

  @media (min-width: $screen-md-min) {
    .our-economists-header {
      padding: 23px 30px 20px;
      img {
        width: 70px;
        height: 70px;
      }
      h1 {
        font-size: $DesktopCopyRegular50;
      }
    }

    .top-economists-container {
      padding: 0 30px 20px 30px;
    }

    .top-economists-container {
      .media {
        margin-bottom: 40px;
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .our-economists-header {
      padding: 45px 40px 24px;
    }

    .top-economists-container {
      padding: 20px 65px;
    }
  }
}

// Our Economists List component
.our-economists-list {
  .container {
    padding: 0 0 50px;
  }

  .row {
    padding: 10px 16px;
    margin: 0;
    border: solid .5px $secondaryBorderLineGray;
    border-top: none;

    .col {
      margin: 0;
      padding: 0;
    }
  }
  .economist-contact {
    word-break: break-all;
    a {
      color: $primaryBlue;
    }
  }

  .economists-list-header {
    background: $primaryBlue;
    border: none;

    .column-header {

      a,
      em {
        color: $primaryWhite;
      }

      a {
        @include bmoFont($MobileCopyRegular16, $fontFamilyLight);

        &:hover {
          text-decoration: none;
        }
      }

      em {
        vertical-align: bottom;
        margin-left: 5px;
      }

      em.fa-sort-down {
        vertical-align: baseline;
      }

      .sort-by-economist-name, .sort-by-economist-coverage {
        cursor: pointer;
        a{
          &:focus{
            outline: 2px solid #fff;
          }
        }

        &.selected a {
          border-bottom: 1px solid $primaryWhite;
        }
      }
    }
  }

  .row {
    align-items: center;

    .economist-details {

      .economist-photo,
      .economist-name {
        cursor: pointer;
      }

      .economist-name,
      .economist-discription {
        @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
      }

      .economist-name {
        color: $primaryBlue;
        margin-bottom: 5px;
        a {
          color: $primaryBlue;
          &:focus,
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .economist-discription {
        color: $secondaryGray;
        line-height: 1.25;
        pointer-events: none;
        cursor: default;
      }
    }

    .economist-contact {
      a {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .container {
      padding: 0 30px 50px;
    }

    .economists-list-header {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }

    .row {
      align-items: normal;
      padding: 8px 25px;

      .economist-details {
        padding-right: 25px;

        .economist-name {
          margin: 5px 0;
        }
      }

      .economist-coverage {
        li {
          @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
          color: $primaryBlue;
          padding-left: 15px;
        }

        ul {
          list-style: none;
        }

        ul li::before {
          content: "\2022";
          color: $medGray;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .container {
      padding: 0 40px 50px;
      ;
    }

    .row {
      .economist-details {
        padding-right: 30px;
        .media{
          a{
            margin-right: 15px;
          }
        }
      }

      .economist-contact {
        a {
          color: $primaryBlue;
          display: block;
          white-space: nowrap;

          img {
            margin-right: 16px;
          }
        }

        a:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  /* Economist Preview modal */
  .economist-preview {
    .modal-dialog {
      @media (max-width: $screen-md-min - 1) {
        margin: 0;
        height: 100vh;
        max-width: 100%;
        border-radius: 0;
      }
    }

    .modal-content {
      @media (max-width: $screen-md-min - 1) {
        min-height: 100%;
        border-radius: 0;
        border: 0;
      }

      .close {
        @include modalCloseMobile();
      }

      .modal-body {
        padding: 30px 15px;
      }
    }

    .media-mobile {
      margin-bottom: 20px;
      img {
        vertical-align: top;
        border-radius: 50%;
      }
      .economist-modal-name {
        display: inline-block;
        margin-left: 10px;
        width: calc(100% - 120px);
        > a {
          @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
          color: $primaryBlue;
          display: block;
          margin-bottom: 5px;
        }
        p {
          @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
          color: $colorGray;
        }

        .economist-modal-email-phone {
          margin-top: 10px;
          .btn {
            width: 21px;
            height: 21px;
            margin-right: 20px;
            background-repeat: no-repeat;

            &.mail-icon {
              background-image: url('assets/images/mail-icon.png');
              background-size: contain;
              &:hover{
                background-image: url('assets/images/envelope-hover.png');
              }
            }

            &.phone-icon {
              background-image: url('assets/images/phone-icon.png');
              background-size: contain;
              &:hover{
                background-image: url('assets/images/phone-hover.png');
              }
            }
            &:focus {
              border: 0;
              outline: $focus-outline;
            }
          }
        }
      }
    }
    .coverage-mobile {
      padding-top: 20px;
      border-top: 1px solid rgba(140, 140, 140, 0.5);
      p {
        @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
        color: $primaryBlue;
      }
      ul {
        padding-top: 20px;
        li {
          @include bmoFont($MobileCopyRegular16, $fontFamilyRegular);
          color: $primaryBlue;
          position: relative;
          padding-left: 15px;
          &::before {
            content: "\2022";
            color: $medGray;
            display: inline-block;
            padding-right: 10px;
            width: 5px;
            font-size: $MobileCopyRegular16;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: -2px;
          }
        }
      }
    }
    .read-about-btn {
      text-align: center;
      .read-about-economist {
        width: auto;
        margin-top: 30px;
        line-height: 30px;
      }
    }
  }
}
